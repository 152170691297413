import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  NgZone,
  OnInit,
} from '@angular/core';
import {
  CANCEL_FLOW,
  GA_CUSTOM_DIMENSION,
  GA_EVENT_ACTION,
  GA_EVENT_CATEGORY,
  PAYMENT_TYPE_TERM,
} from 'apps/di-ld/src/app/core/constant/base.enum';
import dayjs from 'dayjs';
import { Router } from '@angular/router';
import { pathUrl } from 'apps/di-ld/src/app/configs';
import { DecimalPipe } from '@angular/common';
import { AnimationOptions } from 'ngx-lottie';
import {
  ApplicationsService,
  JsInterfaceService,
  PaymentDetailsService,
} from '../../../../../core/services';
import { DialogErrorHandlerService } from '../../../../error-handler/dialog-error-handler.service';
import { environment } from '@environments/environment';
import {
  ICasaAccountInquiry,
  SIX_CHECK_TYPE,
} from 'apps/di-ld/src/app/core/interfaces';
import { AppStateService } from 'apps/di-ld/src/app/app-state.service';
import {
  FirebaseParam,
  EventGATagging,
} from 'apps/di-ld/src/app/core/interfaces/js-interface.interface';

declare const window: any;

@Component({
  selector: 'web-di-ld-status-approve',
  templateUrl: './status-approve.component.html',
  styleUrls: ['./status-approve.component.scss'],
})
export class StatusApproveComponent implements OnInit {
  animateOptions: AnimationOptions = {
    path: '/assets/animation/pending-icon/pending-icon.json',
  };
  @Input() selectedData!: any;
  @Input() getCurrentLang?: string;
  @Input() applicationRef!: string;

  isLoading?: boolean;
  isShowLaterBtn = false;
  isCancelFlow?: boolean;

  public paymentTermEnum = PAYMENT_TYPE_TERM;

  readonly decimalPipe = new DecimalPipe(this._locale);

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private router: Router,
    private applicationsService: ApplicationsService,
    public dialogService: DialogErrorHandlerService,
    private jsInterfaceService: JsInterfaceService,
    public paymentDetailsService: PaymentDetailsService,
    private zone: NgZone,
    private appState: AppStateService
  ) {
    this.onReceiveNativeCommand();
  }

  onReceiveNativeCommand() {
    window['onReceiveNativeCommand'] = (commandName: any, payload: any) => {
      if (commandName === 'eSavingAccountResult') {
        this.zone.run(() => {
          console.log(
            'onReceiveNativeCommand: ' + commandName + ', value: ' + payload
          );
          if (payload === 'true') {
            this.getAccountInquiry();
          }
        });
      }
    };
  }

  ngOnInit(): void {
    this.applicationsService.setGaTaggingBasedOnStatus(
      GA_EVENT_CATEGORY.ACCEPT_APPROVED
    );
    const event = {
      action: GA_EVENT_ACTION.VIEW,
      category: GA_EVENT_CATEGORY.ACCEPT_APPROVED,
      label: '',
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
      {
        key: GA_CUSTOM_DIMENSION.PT_INFORMATION,
        value: `Status:${this.selectedData.application.status}, Reminder:${this.selectedData.application.applicationExpiryCountdownEn}, ExpiryDate:${this.selectedData.application.applicationExpiryDate}`,
      },
    ];

    this.gaTagging(event, dimension);
  }

  arriveDate(date?: string) {
    const dateArrive = dayjs(date);
    const dateNow = dayjs();
    const arriveDate = dateArrive.diff(dateNow, 'day');
    return arriveDate === -1 ? '' : arriveDate + 1;
  }

  onAccept() {
    this.isCancelFlow = false;

    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: GA_EVENT_CATEGORY.ACCEPT_APPROVED,
      label: `${GA_EVENT_CATEGORY.ACCEPT_APPROVED}_Accept`,
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
      {
        key: GA_CUSTOM_DIMENSION.PT_INFORMATION,
        value: `MonthlyInstall: ${this.selectedData.application.maxInstallmentAmount}, Interest: ${this.selectedData.application.offerMaxInterestRate}, Term: ${this.selectedData.application.selectedPaymentTerm}, Status:${this.selectedData.application.status}, Reminder:${this.selectedData.application.applicationExpiryCountdownEn}`,
      },
    ];

    this.gaTagging(event, dimension);

    this.onSubmit();
  }

  addBodyClass() {
    const bodyTag = document.body;
    bodyTag.classList.add('no-title');
  }

  addNoArrowBodyClass() {
    const bodyTag = document.body;
    bodyTag.classList.add('no-arrow');
  }

  removeBodyClass() {
    const bodyTag = document.body;
    bodyTag.classList.remove('no-title');
  }

  removeNoArrowBodyClass() {
    const bodyTag = document.body;
    bodyTag.classList.remove('no-arrow');
  }

  onCancel() {
    this.isCancelFlow = true;
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: GA_EVENT_CATEGORY.ACCEPT_APPROVED,
      label: `${GA_EVENT_CATEGORY.ACCEPT_APPROVED}_Reject`,
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
      {
        key: GA_CUSTOM_DIMENSION.PT_INFORMATION,
        value: `Status:${this.selectedData.application.status}, Reminder:${this.selectedData.application.applicationExpiryCountdownEn}, ExpiryDate:${this.selectedData.application.applicationExpiryDate}`,
      },
    ];
    this.gaTagging(event, dimension);
    this.onSubmit();
  }

  async onSubmit() {
    console.log('onSubmit');
    try {
      // Check Expire
      const isNotExpired = await this.applicationsService.checkExpiredByRef(
        this.applicationRef
      );
      if (isNotExpired) {
        if (this.isCancelFlow) {
          this.router.navigate([pathUrl.turndown], {
            state: {
              applicationRef: this.applicationRef,
              from: CANCEL_FLOW.FROM_APPROVED_FLOW,
            },
          });
        } else {
          this.addBodyClass();
          // check 60 year old
          const isMoreThanSixty = await this.applicationsService.checkBirthDate(
            this.applicationRef
          );
          if (!isMoreThanSixty) {
            this.dialogService.setError('', 'moreThanSixty');
          } else {
            // Six Check
            console.log('six check');
            this.isLoading = true;
            await this.checkEveryAnySecond();
          }
          this.removeBodyClass();
        }
      } else {
        console.log('expired');
        this.addNoArrowBodyClass();
        this.isLoading = false;
        this.dialogService.setError('', 'applicationExpired');
      }
    } catch (error) {
      console.error(error);
    }
  }

  async delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Call six check every 1,3,5,8 second
  async checkEveryAnySecond() {
    await this.delay(1000);
    const isContinue = await this.sixCheck();
    if (isContinue) {
      await this.delay(2000);
      const isContinue = await this.sixCheck();
      if (isContinue) {
        await this.delay(2000);
        const isContinue = await this.sixCheck();
        if (isContinue) {
          await this.delay(3000);
          const isContinue = await this.sixCheck();
          if (isContinue) {
            this.appState.setHeaderTitle('apply-inprogress');
            this.isShowLaterBtn = true;
          }
        }
      }
    }
  }

  async sixCheck() {
    try {
      const isSixCheck = await this.applicationsService.sixCheck(
        this.applicationRef
      );
      const isContinue = await this.sixCheckCondition(
        isSixCheck as SIX_CHECK_TYPE
      );
      return isContinue;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  async sixCheckCondition(status?: SIX_CHECK_TYPE) {
    if (status === SIX_CHECK_TYPE.NOTPASS) {
      this.isLoading = false;
      this.addNoArrowBodyClass();
      this.dialogService.setError('', 'notPassSixCheck');
      return false;
    } else if (status === SIX_CHECK_TYPE.PASS) {
      this.isLoading = false;
      // Check Bank Account
      await this.getAccountInquiry();
      return false;
    } else {
      return true;
    }
  }

  async getAccountInquiry() {
    console.log('getAccountInquiry');
    const accountInquiryData: ICasaAccountInquiry = await this.paymentDetailsService.getCasaAccountInquiry();
    const accountInquiries = accountInquiryData.accountAccept;
    if (accountInquiries.length > 0) {
      this.appState.setAccountInquiries(accountInquiries);
      console.log('open payment details');
      this.applicationsService.setApplication(this.selectedData.application);
      this.router.navigate([pathUrl.paymentDetails]);
    } else {
      console.log('open E-Saving Account');
      this.jsInterfaceService.openESavingAccountAcceptLoan();
    }
  }

  parseToDecimal(value: any) {
    return this.decimalPipe.transform(value, '1.2-2');
  }

  back() {
    this.appState.setHeaderTitle('');
    this.router.navigate([pathUrl.loanProductShelf]);
  }

  close() {
    if (environment.mode === 'local') {
      this.router.navigate([pathUrl.loanProductShelf]);
    } else {
      this.jsInterfaceService.closeWebView();
    }
  }

  gaTagging(event: EventGATagging, dimension?: FirebaseParam[]) {
    this.jsInterfaceService.gaTagging(event, dimension);
  }
}
