export interface IAppStatus {
  customer?: Customer;
  applicationList: ApplicationList[];
}

export interface Customer {
  cifNo: string;
  titleTh: string;
  titleEn: string;
  fullNameTh: string;
  firstNameTh: string;
  middleNameTh: string;
  surnameTh: string;
  fullNameEn: string;
}

export interface ApplicationList {
  applicationRef: string;
  applicationDateTime: string;
  productTypeCode: string;
  productMarketCode: string;
  productCode: string;
  productName: string;
  requestCreditLimit: number;
  interestRate: string;
  paymentTerm: number;
  paymentTermType: string;
  status: string;
  statusDesc: string;
  estimatedMonthlyInstallment: number;
  fee: string;
}

export interface TurndownMaster {
  code: string;
  reasonTh: string;
  reasonEn: string;
  sequence: number;
  productType: string;
  isActive: boolean;
}

export interface RejectDoc {
  loanAppId: string;
  code: string;
  reason: string;
}

export interface RejectLoan {
  applicationReferenceNo: string;
  reasonCode: string;
  reasonDesc: string;
}
