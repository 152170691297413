export enum LOAN_ACCESS_TYPE {
  CALCULATION,
  LOAN_APPLY,
}

export enum LOAN_PRODUCT_TYPE {
  SMARTMONEY = 'DL_SMARTMONEY',
  PAYDAY = 'DL_PAYDAY',
  THUNGNGERN = 'DL_THUNGNGERN',
  _5PLUSTL = 'DL_5PLUS_TL',
  _5PLUSOD = 'DL_5PLUS_OD',
  TOPUP_THUNGNGERN = 'TU_THUNGNGERN',
  TOPUP_SMARTMONEY = 'TU_SMARTMONEY',
  TOPUP_5PLUSTL = 'TU_5PLUS_TL',
}

export enum LOAN_RATE_TYPE {
  FIXEDMAX = 'FIXEDMAX',
  FIXEDRANGE = 'FIXEDRANGE',
  INDEXMAX = 'INDEXMAX',
  INDEXRANGE = 'INDEXRANGE',
}

export enum NAVIGATION_PATH {
  SCREENWEB = 'SCREENWEB',
  DEEPLINK = 'DEEPLINK',
  WEBVIEW_INTERNAL = 'WEBVIEW_INTERNAL',
  WEBVIEW_EXTERNAL = 'WEBVIEW_EXTERNAL',
}

export enum BANNER_TYPE {
  BANNER = 'BANNER',
  PROMO = 'PROMO',
}

export enum BANNER_POSITION {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum BE_ERROR_CODE {
  PRODUCT_EXPIRED = 'OFFE500',
  NO_PAYROLL_ACCOUNT = 'OFFE580',
  PRODUCT_CLOSED = 'OFFE110',
  LOAN_APP_EXPIRED = 'APPE401',
  APPLY_REJECT_LIMIT = 'OFFE521',
  APPLY_DAILY_LIMIT = 'OFFE522',
  APPLY_MONTHLY_LIMIT = 'OFFE523',
  INVALID_CBS_DATA = 'OFFE810',
  CID_NOT_APPLICABLE = 'OFFE311',
  PRODUCT_NOT_ELIGIBLE = 'OFFE414',
  ACC_OPEN_IN_PERIOD = 'OFFE590',
  CBS_MANDATORY_FIELD_MISSING = 'OFFE585',
  CBS_SERVICE_DOWN = 'OFFE801',
  ERROR_EXISTING_LOAN = 'OFFE581',
  ERROR_DISBURSEMENT_ACC = 'OFFE582',
  ERROR_DIRECT_DEBIT_ACC = 'OFFE583',
  TOPUP_CREDIT_LIMIT_LESS_THAN_MINIMUM = 'OFFE533',
  CUSTOMER_MANDATORY_FIELD_MISSING = 'APPE585',
  CUSTOMER_CBS_MAILING_ADDRESS_INVALID = 'APPE811',
  NOT_ELIGIBLE_ANY_PRODUCTS = 'OFFE550',
  INOPROGRESS_OTHER_PRODUCT = 'OFFE540',
  NOT_CIF_ON_CBS = 'ACHE501',
}

export enum FE_ERROR_CODE {
  GENERIC = 'GENERIC_ERROR',
  PEP_OVERSEA_YES = 'PEP_OVERSEA_YES',
  INVALID_AGE = 'INVALID_AGE',
  MISSING_PERSONAL_INFO = 'MISSING_PERSONAL_INFO',
  INVALID_NATIONALITY = 'INVALID_NATIONALITY',
  INVALID_CBS_DATA = 'INVALID_CBS_DATA',
  COUNTRY_IS_NOT_TH = 'COUNTRY_IS_NOT_TH',
  CID_NOT_APPLICABLE = 'CID_NOT_APPLICABLE',
  NCB_CONSENT_NOT_APPLICABLE = 'NCB_CONSENT_NOT_APPLICABLE',
  AIS_CONSENT_CONFIRMED_CAMPAIGN_END = 'AIS_CONSENT_CONFIRMED_CAMPAIGN_END',
  AIS_KTB_TERM_AND_CON_USER_REJECT = 'AIS_KTB_TERM_AND_CON_USER_REJECT',
  AIS_CONSENT_USER_REJECT = 'AIS_CONSENT_USER_REJECT',
  AIS_OTP_INCORRECT = 'AIS_OTP_INCORRECT',
  AIS_OTP_EXPIRED = 'AIS_OTP_EXPIRED',
  AIS_OTP_EXCEEDED = 'AIS_OTP_EXCEEDED',
}

export enum EDIT_INFO_TYPE {
  MAILING_ADDRESS = 'MAILING_ADDRESS',
  BUSINESS_INFO = 'BUSINESS_INFO',
}

export enum GA_EVENT_ACTION {
  MIGRATION = 'Migration',
  CLICK = 'Click',
  SLIDE = 'Slide',
  COMPLETE = 'Complete',
  ERROR = 'Error',
  AUTHENTICATE = 'Authenticate',
  VIEW = '',
}

export enum GA_EVENT_CATEGORY {
  PRODUCT_SHELF = '',
  LOAN_APPLY = 'DGL_PT_OfferProductHighlight',
  PRODUCT_DETAIL = 'DGL_PT_OfferSalesSheetPDF',
  LOAN_CALCULATE = 'DigitalLending_LoanWebLoanCalculation',
  PERSONAL_INFORMATION = 'DGL_PT_ApplyPersonalInfo',
  ADDITIONAL_INFORMATION = 'DGL_PT_ApplyAdditionalInfo',
  EDIT_INFORMATION = 'DGL_PT_ApplyUpdateEmployment',
  CONFIRM_DETAIL = 'DGL_PT_ApplyConfirmDetails',
  MARKETING_CONSENT = 'DGL_PT_ApplyModelConsent',
  NCB_CONSENT = 'DGL_PT_ApplyNCBConsent',
  PROMOTION = 'DigitalLending_LoanWebMarketing',
  TOPUP_LOAN_DETAIL = 'DigitalLending_LoanWebTopupInfo',
  TOPUP_ADDITIONAL_DETAIL = 'DigitalLending_LoanWebTopupAddInfo',
  TOPUP_CONFIRMATION = 'DigitalLending_LoanWebTUConfirm',
  EDIT_MAILING_ADDRESS = 'DigitalLending_LoanWebUpdateMailing',
  RATING = 'DigitalLending_LoanWebRating',
  APPLY_SUCCESS = 'DGL_PT_ApplyPendingApprove',
  ACCEPT_APPROVED = 'DGL_PT_AcceptApproved',
  PAYMENT_DETAILS = 'DGL_PT_AcceptPaymentDetail',
  UPDATE_MAILING = 'DGL_PT_AcceptUpdateMailing',
  CONFIRM_INFO = 'DGL_PT_AcceptConfirmInfo',
  ACCEPT_CONTRACT = 'DGL_PT_AcceptContractT&C',
  ACCEPT_CONTRACT_COMPLETED = 'DGL_PT_AcceptContractCompleted',
  FOLLOWUP_REVIEW_DOC_UPLOAD = 'DGL_PT_FollowUpReviewDocUpload',
  PENDING_APPROVE = 'DGL_PT_ApplyPendingApprove',
  TURNDOWN = 'DGL_PT_AcceptSelectedReason',
  APP_STATUS_LIST = 'DGL_PT_AppTrackingAppStatusList',
  STATUS_UNSUCCESS = 'DGL_PT_AppTrackingAppStatusDetails',
  AIS_T_AND_C = 'DGL_PT_ApplyAIST&C',
  CP_MISMATCH_CON = 'DGL_PT_ApplyCPMismatchCon',
  AIS_OTP = 'DGL_PT_ApplyAISOTP',
  AIS_CONSENT = 'DGL_PT_ApplyAISConsent',
}

export enum APPFLYER_EVENT_LABEL {
  LOAN_APPLY = 'DGL_PT_OfferProductHighlight',
  OPEN_ESAVING_POPUP = 'DGL_PT_OfferProductHighlight_Popup',
  PERSONAL_INFORMATION_NEXT = 'DGL_PT_PersonalData_Next',
  ADDITIONAL_INFORMATION_CONFIRM = 'DGL_PT_PersonalData_Confirm',
  LOAN_AIS_TC_VIEW = 'DGL_PT_PartnerT&C',
  LOAN_AIS_TC_NEXT = 'DGL_PT_PartnerT&C_Next',
}

export enum GA_CUSTOM_DIMENSION {
  PAYMENT_DESTINATION = 3,
  INFORMATION = 4,
  HTTP_STATUS_CODE = 5,
  ERROR_CODE = 6,
  IS_FIN_TXN_ERROR = 7,
  ERROR_TITLE = 8,
  ERROR_DISPLAY_TYPE = 9,
  SOF_PRODUCT_CODE = 10,
  FEE = 11,
  SEARCH_TERM = 12,
  NOTIFICATION_DATE = 13,
  BILLER_NAME = 15,
  BILLER_CATEGORY_PRODUCT_TYPE = 16,
  SCHEDULE_TYPE = 17,
  SCHEDULE_DURATION = 18,
  CAMPAIGN_NOTI_BANNER_ID = 19,
  ITEM_ORDER = 20,
  MIGRATION = 21,
  CORRELATION_ID = 22,
  FUND_CODE = 23,
  FUND_TYPE = 24,
  PARTNER_APP = 25,
  REGISTRATION_EKYC = 26,
  NDID = 27,
  SWIFT_CODE = 28,
  INVESTMENT_OBJECT = 29,
  MONTHLY_INCOME = 30,
  SOURCE_OF_INCOME = 31,
  COUNTRY = 32,
  OCCUPATION = 33,
  BUSINESS_TYPE = 34,
  AMC_CODE = 35,
  COMPANY_ABBR = 36,
  REFERRAL_CODE = 37,
  PRODUCT_TYPE = 38,
  POSITION = 39,
  CAMPAIGN_TYPE = 40,
  KEY = 'product_type',
  PT_INFORMATION = 'pt_information',
}

export enum ERROR_TYPE {
  FULLSCREEN = 'FULL_SCREEN_ERROR',
  POPUP = 'POPUP_SCREEN_ERROR',
  GENERIC_ERROR = 'GENERIC_ERROR',
}

export enum SCREEN_MODE {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export enum PAYMENT_TERM {
  MONTHS = 'เดือน',
}

export enum PAYMENT_TYPE_TERM {
  MONTHS_TH = 'เดือน',
  MONTHS_EN = 'Months',
}

export const ACCEPT_CONTRACT_FLOW = 'ACCEPT_CONTRACT_FLOW';

export enum CANCEL_FLOW {
  FROM_APPROVED_FLOW = 'FROM_APPROVED_FLOW',
  FROM_UPLOAD_DOCUMENT_FLOW = 'FROM_UPLOAD_DOCUMENT_FLOW',
}

export enum APPLICATION_STATUS {
  APPROVED = 'APPROVED',
  ACCEPTED = 'ACCEPTED',
  ACCEPTED_FROM_PAOTANG = 'ACCEPTED_FROM_PAOTANG',
  PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL',
  PENDING_DOCUMENT_UPLOAD = 'PENDING_DOCUMENT_UPLOAD',
  RESUBMIT_DOCUMENT = 'RESUBMIT_DOCUMENT',
  PROCESSING = 'PROCESSING',
  CANCELED = 'CANCELED',
  CANCELED_BY_BANK = 'CANCELED_BY_BANK',
  OFFER_TURNED_DOWN = 'OFFER_TURNED_DOWN',
  OFFER_TURNED_DOWN_FROM_PAOTANG = 'OFFER_TURNED_DOWN_FROM_PAOTANG',
  REJECTED_BY_COMPLIANCE = 'REJECTED_BY_COMPLIANCE',
  REJECTED_BY_BANK = 'REJECTED_BY_BANK',
  APPROVAL_EXPIRED = 'APPROVAL_EXPIRED',
  REJECTED_BY_FOLLOWUP = 'REJECTED_BY_FOLLOWUP',
  LOAN_SET_UP_COMPLETED = 'LOAN_SET_UP_COMPLETED',
  REJECTED_MISSING_DOCUMENT = 'REJECTED_MISSING_DOCUMENT',
  REJECTED_FAILED_SCREENING_FROM_PAOTANG = 'REJECTED_FAILED_SCREENING_FROM_PAOTANG',
  REJECTED_FAILED_SCREENING = 'REJECTED_FAILED_SCREENING',
}

export enum PAGE_NAME {
  APPLY_LOAN = 'titleHeader.applyLoan',
  PRODUCT_DETAIL = 'titleHeader.productDetail',
  CALCULATION = 'titleHeader.calculation',
  TOP_UP = 'titleHeader.topup',
  CONFIRM_DETAIL = 'titleHeader.confirmingDetails',
  PERSONAL_INFORMATION = 'titleHeader.personalInformation',
  ADDITIONAL_INFORMATION = 'titleHeader.additionalInformation',
  EDIT_INFORMATION = 'titleHeader.editInformation',
  CONSENT = 'titleHeader.consent',
  CONFIRM_SUMMARY = 'titleHeader.confirmSummary',
  APP_INPROGRESS = 'titleHeader.appInprogress',
  APP_DETAIL = 'titleHeader.appDetails',
  APPLY_SUCCESS = 'titleHeader.applySuccess',
  PRODUCT_SHELF = 'titleHeader.productShelf',
  APPLY_CONFIRMATION_INFO = 'titleHeader.applyConfirmationInfo',
  APPLY_RATING = 'titleHeader.applyRating',
}

export enum PAYMENT_INFO_ERROR_DIALOG {
  UNAVAILABLE_STAMP_DUTY = 'UNAVAILABLE_STAMP_DUTY',
  UNAVAILABLE_STAMP_DUTY_WITH_MULTIPLE_ACCOUNT = 'UNAVAILABLE_STAMP_DUTY_WITH_MULTIPLE_ACCOUNT',
  RESTRICTION = 'RESTRICTION',
}

export enum AIS_ERROR_CODE {
  BLOCKED_USER = 'AISE521',
  INCORRECT_PWD = 'AISE511',
  BLOCKED_USER_3_ATTEMPTS = 'AISE512',
  CONSENT_CONFIRM_OTP_EXPIRED = 'AISE513',
  TOKEN_CONSENT_PROFILE_EMPTY = 'AISE501',
  CONSENT_VERSION_NOT_MATCH = 'AISE502',
  ELIGIBILITY_CHECK_FAILED = 'AISE530',
  CUSTOMER_PROFILE_NOT_FOUND = 'AISE531',
}
