import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { EMPTY, Observable, Subject, Subscription, throwError } from 'rxjs';

import { AppStateService } from '../../../app-state.service';
import { HttpService } from '@web-di-ld/apps/http';
import { BE_ERROR_CODE, LOAN_PRODUCT_TYPE } from '../../constant/base.enum';
import { IDraft } from '../../interfaces/draft.interface';

// service
@Injectable({
  providedIn: 'root',
})
export class DraftService {
  backEvtSubject = new Subject<any>();
  callSaveDraft: boolean = true;
  productDraft: any;

  constructor(
    private httpClient: HttpClient,
    public appState: AppStateService,
    public httpService: HttpService
  ) {}

  setProductDraft(productDraft: any) {
    this.productDraft = productDraft;
  }

  getDraft(productCode: LOAN_PRODUCT_TYPE): any {
    console.log('===== getDraft =====');
    return this.httpService
      .get(`/lfm-applications/app/v1/application/products/${productCode}/draft`)
      .toPromise()
      .then((response: any) => {
        if (response) {
          this.appState.updateStateDraft(response.data);
          return response;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
        // this.handleError(error);
        return null;
      });
  }

  postDraft(draft: IDraft) {
    console.log('postDraft => check draft before call api postDraft: ', draft);
    const productCode = draft.loanInfo?.productCode
      ? draft.loanInfo?.productCode
      : this.appState.productCode;
    console.log('postDraft => productCode:', productCode);
    return this.httpService
      .post(
        `/lfm-applications/app/v1/application/products/${productCode}/draft`,
        draft,
        false
      )
      .toPromise()
      .then((response: any) => {
        console.log('postDraft => response: ', response);
        if (response) {
          return response;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.log('postDraft => error: ', error);
        console.error(error);
        if (error.error.code === BE_ERROR_CODE.LOAN_APP_EXPIRED) {
          // loan app expired, then no need to save draft
          this.callSaveDraft = false;
        }
        return null;
      });
  }

  saveDraft() {
    const draft: IDraft = this.appState.snapshot.draft;
    console.log('saveDraft => draft before postDraft: ', draft);
    console.log('🚀 saveDraft => callSaveDraft is ', this.callSaveDraft);
    if (typeof draft === 'object' && draft !== null) {
      if (Object.keys(draft).length && this.callSaveDraft) {
        this.postDraft(draft);
      }
    }
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }
}
