import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'web-di-ld-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  @Input() displayButton: boolean = true;
  @Input() onPage: string = '';
  @Output() clickEvt = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onPageText() {
    if (this.onPage === 'review_info') {
      return {
        title: 'confirmInfoVerify.title',
        description: 'confirmInfoVerify.description',
      };
    }
    return {
      title: 'applyVerify.title',
      description: 'applyVerify.description',
    };
  }

  emitClickEvt() {
    this.clickEvt.emit();
  }
}
