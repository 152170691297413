import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import {
  ApplyLoan,
  FirebaseParam,
  DeeplinkBody,
  EventGATagging,
  AppflyerParam,
} from '../../interfaces/js-interface.interface';
import { pathUrl } from '../../../configs/pathUrl';
import { AppStateService } from '../../../app-state.service';
import {
  LOAN_PRODUCT_TYPE,
  GA_CUSTOM_DIMENSION,
} from '../../constant/base.enum';

export interface JavascriptCallback {
  openAppDeeplink(data: string): any;
  enterFullScreen(data: string): any;
  exitFullScreen(data: any): any;
  exitFullScreenToPageRating(): any;
  internalPartnerPinVerify(data: string): any;
  analyticTracking(data: any): any;
  applyLoan(data: any): any;
  backToHome(): any;
  openWebUrl(data: string): any;
  openHiddenWebUrl(data: string): any;
  openDeeplink(deeplinkUrl: string): any;
  shareText(url: string): any;
  openESavingAccount(): any;
  openFollowup(): any;
  checkEmailConfirmation(): any;
  openEmailVerification(): any;
  closeWebView(): any;
}

declare const LoanWeb: JavascriptCallback;
declare const window: any;
declare const webkit: any;

@Injectable({
  providedIn: 'root',
})
export class JsInterfaceService {
  isVerifyEmail = false;
  constructor(
    private router: Router,
    private location: Location,
    private appState: AppStateService
  ) {}

  public setIsVerifyEmail(value: boolean) {
    this.isVerifyEmail = value;
  }

  public enterFullScreen(url?: string, headerTitle?: string, dataState?: any) {
    // const urlCombine = `${environment.baseUrl}${url}`;
    // console.log('enterFullScreen: ', urlCombine);
    // const body = JSON.stringify({
    //   redirectUrl: urlCombine,
    // });
    // const ua = navigator.userAgent.toLowerCase();
    // const isAndroid = ua.indexOf('android') > -1;
    // try {
    //   if (isAndroid) {
    //     const android = LoanWeb;
    //     console.log('isAndroid');
    //     android.enterFullScreen(body);
    //   } else {
    //     window.webkit.messageHandlers.enterFullScreen.postMessage(body);
    //   }
    // } catch (error) {
    //   console.log('isWeb');
    // } finally {
    this.router.navigate([url], {
      skipLocationChange: false,
      state: { headerTitle, dataState },
    });
    // }
  }

  public openDeepLink(data?: DeeplinkBody) {
    console.log('openAppDeeplink', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    try {
      if (isAndroid) {
        let android = LoanWeb;
        console.log('isAndroid');
        android.openAppDeeplink(body);
      } else {
        window.webkit.messageHandlers.openAppDeeplink.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  public openDeeplinkNext(deeplinkUrl: string) {
    const data = { deeplink: deeplinkUrl };
    console.log('openDeeplink: ', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        let android = LoanWeb;
        console.log('isAndroid');
        android.openDeeplink(body);
      } else {
        window.webkit.messageHandlers.openDeeplink.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  public exitFullScreen(previousUrl?: string) {
    console.log('exitFullScreen');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        let android = LoanWeb;
        console.log('isAndroid');
        android.exitFullScreen(null);
      } else {
        window.webkit.messageHandlers.exitFullScreen.postMessage(null);
      }
    } catch (error) {
      console.log('isWeb');
    } finally {
      // this.appState.isCloseWebView = true;
      console.log('exitFullScreen here');
      // if (this.appState.isCloseWebView) {
      //   this.closeWebView();
      // }
      if (previousUrl) this.router.navigate([previousUrl]);
      else this.location.back();
    }
  }

  public exitFullScreenToPageRating() {
    console.log('exitFullScreenToPageRating');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        let android = LoanWeb;
        console.log('isAndroid');
        android.exitFullScreenToPageRating();
      } else {
        window.webkit.messageHandlers.exitFullScreenToPageRating.postMessage(
          null
        );
      }
    } catch (error) {
      console.log('isWeb');
    } finally {
      this.location.back();
    }
  }

  public internalPartnerPinVerify(partnerReqId: string) {
    console.log('internalPartnerPinVerify', partnerReqId);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    try {
      if (isAndroid) {
        window.JSBridge['internalPartnerPinVerify']?.(partnerReqId);
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'internalPartnerPinVerify',
          partnerReqId,
        });
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  // Bank In Bank Flow
  public openBankInBank() {
    console.log('openBankInBank');

    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    try {
      if (isAndroid) {
        window.JSBridge['openKTBLoanAccount']?.();
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'openKTBLoanAccount',
        });
      }
    } catch (error) {
      console.log('isWeb -> openKTBLoanAccount:' + error);
    }
  }

  public openFollowup() {
    console.log('openFollowup');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    try {
      if (isAndroid) {
        window.JSBridge['openFollowup']?.();
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'openFollowup',
        });
      }
    } catch (error) {
      console.log('openFollowup:' + error);
      console.log('isWeb');
    }
  }

  public gaTagging(
    event: EventGATagging,
    firebaseParam?: FirebaseParam[],
    trackAppsflyer: boolean = false,
    trackGA: boolean = true,
    appsflyerParam?: AppflyerParam[]
  ) {
    // remove pt_information for tracking Appsflyer
    if (trackAppsflyer && firebaseParam) {
      const indexOfPTInformation = firebaseParam.findIndex(
        (object) => object.key === GA_CUSTOM_DIMENSION.PT_INFORMATION
      );
      if (indexOfPTInformation !== -1)
        firebaseParam?.splice(indexOfPTInformation, 1);
    }
    var data;
    //appflyerParam
    if (appsflyerParam !== undefined) {
      data = { event, trackAppsflyer, trackGA, appsflyerParam };
    } else {
      data = firebaseParam
        ? {
            event,
            firebaseParam,
            trackAppsflyer,
            trackGA,
          }
        : { event, trackAppsflyer, trackGA };
    }

    console.log('========== START GA TAGGING ==========');
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    console.log('Platform =>', isAndroid ? 'Android' : 'iOS');
    console.log('Tagging At', new Date().toString());
    console.log('Payload =>', data);
    try {
      if (isAndroid) {
        window.JSBridge['analyticTracking'](body);
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'analyticTracking',
          data: body,
        });
      }
      console.log('Status => Success');
    } catch (error) {
      // handle when open on web
      console.log('Status => Fail');
      console.log(`Error => ${error}`);
    } finally {
      console.log('=========== END GA TAGGING ===========');
    }
  }

  public applyLoan(data?: ApplyLoan) {
    console.log('applyLoan', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    try {
      if (isAndroid) {
        let android = LoanWeb;
        console.log('isAndroid');
        android.applyLoan(body);
      } else {
        window.webkit.messageHandlers.applyLoan.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  public checkEmailConfirmation() {
    console.log('checkEmailConfirmation');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    try {
      if (isAndroid) {
        window.JSBridge['checkEmailConfirmation']?.();
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'checkEmailConfirmation',
        });
      }
    } catch (error) {
      console.log('checkEmailConfirmation:' + error);
      console.log('isWeb');
    }
  }

  public openEmailVerification() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    try {
      if (isAndroid) {
        window.JSBridge['openEmailVerification']?.();
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'openEmailVerification',
        });
      }
    } catch (error) {
      console.log('openEmailVerification:' + error);
      console.log('isWeb');
    }
  }

  public closeWebView() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;

    try {
      if (isAndroid) {
        console.log('closeWebView: and 1');
        window.JSBridge['closeWebview']?.();
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'closeWebview',
        });
      }
    } catch (error) {
      console.log('closeWebView:' + error);

      console.log('isWeb');
    }
  }

  public backToHome() {
    this.router.navigateByUrl('/');
  }

  openExternalWebview(url: string) {
    const data = { webUrl: url };
    console.log('openExternalWebview: ', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        let android = LoanWeb;
        console.log('isAndroid');
        android.openWebUrl(body);
      } else {
        window.webkit.messageHandlers.openWebUrl.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  openHiddenWebUrl(url: string) {
    const data = { webUrl: url };
    console.log('openHiddenWebUrl: ', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        let android = LoanWeb;
        console.log('isAndroid');
        android.openHiddenWebUrl(body);
      } else {
        window.webkit.messageHandlers.openHiddenWebUrl.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  shareText(url: string) {
    const data = { text: url };
    console.log('shareText: ', data);
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        let android = LoanWeb;
        console.log('isAndroid');
        android.shareText(body);
      } else {
        window.webkit.messageHandlers.shareText.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  public generateApplicationRef(systemRef: string, campaignID: string) {
    const crypto = window.crypto || window.msCrypto;
    var array = new Uint32Array(1);
    crypto.getRandomValues(array);

    const year = dayjs().format('YY');
    const month = dayjs().format('MM');
    const date = dayjs().format('DD');
    const randomNumber = Math.floor(100000 + array[0] * 900000);
    return `${systemRef}${campaignID}${year}${month}${date}${randomNumber}`;
  }

  public onReceiveNativeCommand(command: string, payload: string) {
    if (command === 'internalPartnerPinVerifyCallback') {
      this.router.navigate([pathUrl.applySuccess], {
        state: { pinVerRefId: payload },
      });
    }
  }

  public openESavingAccountAcceptLoan() {
    console.log('openESavingAccountAcceptLoan');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        window.JSBridge['openESavingAccountAcceptLoan']?.();
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'openESavingAccountAcceptLoan',
        });
      }
    } catch (error) {
      console.log('openESavingAccountAcceptLoan:' + error);
      console.log('isWeb');
    }
  }

  public openESavingAccountApplyLoan() {
    console.log('openESavingAccountApplyLoan');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        window.JSBridge['openESavingAccountApplyLoan']?.();
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'openESavingAccountApplyLoan',
        });
      }
    } catch (error) {
      console.log('openESavingAccountApplyLoan:' + error);
      console.log('isWeb');
    }
  }

  public openESavingAccountWhenNoCIF(productCode: LOAN_PRODUCT_TYPE) {
    console.log('openESavingAccountWhenNoCIF');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        window.JSBridge['openESavingAccountWhenNoCIF']?.(productCode);
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'openESavingAccountWhenNoCIF',
        });
      }
    } catch (error) {
      console.log('openESavingAccountWhenNoCIF:' + error);
      console.log('isWeb');
    }
  }

  public getLoanAppId() {
    console.log('getLoanAppId');
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        window.JSBridge['getLoanAppId']?.();
      } else {
        window.webkit.messageHandlers.observer.postMessage({
          command: 'getLoanAppId',
        });
      }
    } catch (error) {
      console.log('getLoanAppId:' + error);
      console.log('isWeb');
    }
  }
}
