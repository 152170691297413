import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import {
  IApplications,
  ICheckExpired,
  ISixCheck,
  GaTaggingInfo,
} from '../../interfaces';
import { HttpService } from '@web-di-ld/apps/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  private applications$ = new BehaviorSubject<any>({});
  selectedApplication$ = this.applications$.asObservable();
  gaTaggingCategory!: string;
  gaTaggingInfo!: GaTaggingInfo;
  constructor(private httpService: HttpService) {}

  async getApplications(applicationRef?: string): Promise<IApplications> {
    return this.httpService
      .get<IApplications>(
        `/lfm-applications/app/v2/application/active-list${
          applicationRef ? `/${applicationRef}` : ''
        }`
      )
      .toPromise()
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return EMPTY;
      });
  }

  async verifyPin(
    pinVerRefId: string,
    referenceAppNo: string,
    partnerReqId: string
  ) {
    return this.httpService
      .post(
        `/lfm-applications/app/v2/application/pin-verify`,
        {
          pinVerRefId: pinVerRefId,
          referenceAppNo: referenceAppNo,
          partnerReqId: partnerReqId,
        },
        false
      )
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }

  async checkExpiredByRef(applicationRef?: string) {
    return this.httpService
      .get<ICheckExpired>(
        `/lfm-accept/app/v1/check_expired${
          applicationRef ? `/${applicationRef}` : ''
        }`
      )
      .toPromise()
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return EMPTY;
      });
  }

  async sixCheck(applicationRef?: string) {
    return this.httpService
      .post<ISixCheck>(
        `/lfm-accept/app/v1/six_check/${applicationRef}`,
        {},
        false
      )
      .toPromise()
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return EMPTY;
      });
  }

  async checkBirthDate(applicationReferenceId?: string) {
    return this.httpService
      .post(
        `/lfm-accept/app/v1/check_birthdate/${applicationReferenceId}`,
        {},
        false
      )
      .toPromise()
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return EMPTY;
      });
  }

  setApplication(product: any) {
    this.applications$.next(product);
  }

  setGaTaggingBasedOnStatus(category: string) {
    this.gaTaggingCategory = category;
  }

  getGaTaggingBasedOnStatus() {
    return this.gaTaggingCategory;
  }

  setGaTaggingInfo(gaTaggingInfo: any) {
    this.gaTaggingInfo = {
      ...this.gaTaggingInfo,
      ...gaTaggingInfo,
    };
  }

  getGaTaggingInfo() {
    return this.gaTaggingInfo;
  }
}
