import { HttpClientModule } from '@angular/common/http';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { AppsHttpModule } from '@web-di-ld/apps/http';
import { UiMobileSelectModule } from '@web-di-ld/ui/mobile/select';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslocoRootModule } from './core/transloco-root.module';
import { LoanProductShelfModule } from './pages/loan-product-shelf/loan-product-shelf.module';
import { MainComponent } from './core/template/main/main.component';
import { UiModule } from './shared/components/ui/ui.module';
import { CookieModule } from 'ngx-cookie';
import { ErrorHandlerModule } from './shared/error-handler/error-handler.module';
import { VersionComponent } from './pages/version/version.component';
import { OnlineEventService } from './core/services/online-event/online-event.service';

@NgModule({
  declarations: [AppComponent, MainComponent, VersionComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    TranslocoRootModule,
    ErrorHandlerModule,
    BrowserAnimationsModule,
    LoanProductShelfModule,
    AppsHttpModule.forEnvironment(environment),
    AppRoutingModule,
    UiModule,
    CookieModule.forRoot(),
  ],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'THB' },
    UiMobileSelectModule,
    OnlineEventService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
