import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'web-di-ld-check-app',
  templateUrl: './check-app.component.html',
  styleUrls: ['./check-app.component.scss']
})
export class CheckAppComponent implements OnInit {
  @Output() onClick = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onNavigateToAppInprogress() {
    this.onClick.emit();
  }
}
