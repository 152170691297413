import { Injectable } from '@angular/core';

const dayOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const dataDate = {
  th: {
    month: [
      { fullName: 'มกราคม', shortName: 'ม.ค.' },
      { fullName: 'กุมภาพันธ์', shortName: 'ก.พ.' },
      { fullName: 'มีนาคม', shortName: 'มี.ค.' },
      { fullName: 'เมษายน', shortName: 'เม.ย.' },
      { fullName: 'พฤษภาคม', shortName: 'พ.ค.' },
      { fullName: 'มิถุนายน', shortName: 'มิ.ย.' },
      { fullName: 'กรกฎาคม', shortName: 'ก.ค.' },
      { fullName: 'สิงหาคม', shortName: 'ส.ค.' },
      { fullName: 'กันยายน', shortName: 'ก.ย.' },
      { fullName: 'ตุลาคม', shortName: 'ต.ค.' },
      { fullName: 'พฤศจิกายน', shortName: 'พ.ย.' },
      { fullName: 'ธันวาคม', shortName: 'ธ.ค.' },
    ],
    year: 543,
  },
  en: {
    month: [
      { fullName: 'January', shortName: 'Jan' },
      { fullName: 'February', shortName: 'Feb' },
      { fullName: 'March', shortName: 'Mar' },
      { fullName: 'April', shortName: 'Apr' },
      { fullName: 'May', shortName: 'May' },
      { fullName: 'June', shortName: 'Jun' },
      { fullName: 'July', shortName: 'Jul' },
      { fullName: 'August', shortName: 'Aug' },
      { fullName: 'September', shortName: 'Sep' },
      { fullName: 'October', shortName: 'Oct' },
      { fullName: 'November', shortName: 'Nov' },
      { fullName: 'December', shortName: 'Dec' },
    ],
    year: 0,
  },
};

// service
@Injectable({
  providedIn: 'root',
})
export class DateService {
  concatDateOfBirth(date: string, lang: string) {
    let dateOfBirth = date.split('-');
    const dateLang = lang === 'th' ? dataDate.th : dataDate.en;
    return (
      dateOfBirth[2] +
      ' ' +
      dateLang.month[Number(dateOfBirth[1]) - 1].shortName +
      ' ' +
      Number(Number(dateOfBirth[0]) + dateLang.year)
    );
  }

  findAge(date: string) {
    const birthDate = date.split('-');
    const birthDay = parseInt(birthDate[2]);
    const birthMonth = parseInt(birthDate[1]);
    const birthYear = parseInt(birthDate[0]);
    const currentDate: Date = new Date();

    let currentDay = currentDate.getDate();
    let currentMonth = currentDate.getMonth() + 1;
    let currentYear = currentDate.getFullYear();

    if (birthDay > currentDay) {
      currentDay = currentDay + dayOfMonth[birthMonth - 1];
      currentMonth = currentMonth - 1;
    }

    if (birthMonth > currentMonth) {
      currentYear = currentYear - 1;
      currentMonth = currentMonth + 12;
    }

    const resultDay = currentDay - birthDay;
    const resultMonth = currentMonth - birthMonth;
    const resultYear = currentYear - birthYear;
    const age = {
      year: resultYear,
      month: resultMonth,
      day: resultDay,
    };
    return age;
  }

  checkValidAge(ageY: number, ageM: number) {
    const validAge = 59;
    return ageY * 12 + ageM <= validAge * 12 ? true : false;
  }

  concatInterestDate(data: string, lang: string) {
    const date = new Date(data);

    if (Object.prototype.toString.call(date) === '[object Date]') {
      // it is a date
      if (isNaN(date.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        return '';
      } else {
        // date is valid
        let result = '';
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const month =
          lang === 'th'
            ? dataDate.th.month[monthIndex].shortName
            : dataDate.en.month[monthIndex].shortName;
        const year =
          lang === 'th'
            ? dataDate.th.year + date.getFullYear()
            : dataDate.en.year + date.getFullYear();
        result = day + ' ' + month + ' ' + year;
        return result;
      }
    } else {
      // not a date
      return '';
    }
  }

  concatNewApplyDate(rejectDate: string, lang: string) {
    let date = new Date(rejectDate);
    if (Object.prototype.toString.call(date) === '[object Date]') {
      // it is a date
      if (isNaN(date.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        return '';
      } else {
        // date is valid
        let result = '';
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const month =
          lang === 'th'
            ? dataDate.th.month[monthIndex].shortName
            : dataDate.en.month[monthIndex].shortName;
        const year =
          lang === 'th'
            ? dataDate.th.year + date.getFullYear()
            : dataDate.en.year + date.getFullYear();
        result = day + ' ' + month + ' ' + year.toString().substring(2);
        return result;
      }
    } else {
      // not a date
      return '';
    }
  }

  checkAgeAndInstallmentTerm(
    dob: string,
    nationality: string,
    paymentTerm: number
  ) {
    const age = this.findAge(dob);

    if (
      nationality === 'TH' &&
      age.year >= 20 &&
      this.checkValidAge(age.year, age.month) &&
      this.checkValidInstallment(age.year, age.month, age.day, paymentTerm)
    ) {
      return true;
    }
    return false;
  }

  checkAgeAndTerm(dob: string, paymentTerm: number) {
    const age = this.findAge(dob);

    if (
      age.year >= 20 &&
      this.checkValidAge(age.year, age.month) &&
      this.checkValidInstallment(age.year, age.month, age.day, paymentTerm)
    ) {
      return true;
    }
    return false;
  }

  checkValidInstallment(
    ageYear: number,
    ageMonth: number,
    ageDay: number,
    paymentTerm: number
  ) {
    const yearValid = 59;
    const monthValid = 11;
    const dayValid = 0; // 0 = 30 day

    const numPaymentTerm = paymentTerm > 12 ? paymentTerm / 12 : paymentTerm;
    const monthPaymentTerm =
      paymentTerm > 12
        ? paymentTerm - Math.floor(numPaymentTerm) * 12
        : paymentTerm;
    const yearPaymentTerm = paymentTerm > 12 ? Math.floor(numPaymentTerm) : 0;

    const sumMonth =
      monthPaymentTerm + ageMonth > 12
        ? (monthPaymentTerm + ageMonth) / 12
        : monthPaymentTerm + ageMonth;
    const yearOfSumMonth =
      monthPaymentTerm + ageMonth > 12 ? Math.floor(sumMonth) : 0;

    const year = ageYear + yearPaymentTerm + yearOfSumMonth;
    const month =
      monthPaymentTerm + ageMonth > 12
        ? monthPaymentTerm + ageMonth - Math.floor(sumMonth) * 12
        : monthPaymentTerm + ageMonth;

    // sum user age and payment term more than 59 year 11 month and 1 day
    if (
      year > yearValid ||
      (yearValid === year && month >= monthValid && ageDay >= dayValid)
    ) {
      return false;
    }

    return true;
  }
}
