<div class="container">
  <ng-container *transloco="let t">
    <div class="p-d-flex p-ai-center p-jc-center p-d-flex-col">
      <div *ngIf="isCanceled()">
        <img
          src="assets/images/app-detail-cancel-by-user.png"
          alt=""
          width="240px"
        />
      </div>
      <div *ngIf="isRejected()">
        <img src="assets/images/app-detail-reject.png" alt="" width="240px" />
      </div>
      <div *ngIf="isExpired()">
        <img src="assets/images/app-detail-expired.png" alt="" width="240px" />
      </div>
      <h3 class="p-m-0" id="divApplicationStatusValue">
        {{ selectedData?.application?.statusDesc }}
      </h3>
      <p
        class="information-message p-mt-2 p-text-center"
        id="divApplicationStatusNotification"
      >
        {{ selectedData?.application?.statusRemark }}
      </p>
    </div>
  </ng-container>
</div>
