import { formatDate, registerLocaleData } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import localeTh from '@angular/common/locales/th';

@Pipe({
  name: 'customThDate',
})
export class CustomThDatePipe implements PipeTransform {
  transform(
    value: string | number | Date | undefined,
    format: string,
    locale = 'th'
  ): string {
    const TH_MONTHS_SHORT = [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ];

    if (locale === 'th' && value) {
      // Replace "MMM" with the corresponding Thai short month name
      format = format.replace(/MMM/g, (match) => {
        const monthIndex = new Date(value).getMonth();
        return TH_MONTHS_SHORT[monthIndex];
      });

      // Replace "yy" with the corresponding Thai short year name Buddhist Era
      format = format.replace(/yy/g, (match) => {
        const date = new Date(value);
        const yearBE = date.getFullYear() + 543; // convert AD year to BE year
        const buddhistYear = yearBE.toString().substring(2); // extract last two digits of BE year
        return buddhistYear;
      });

      registerLocaleData(localeTh);
    }

    return value ? formatDate(value, format, locale) : '';
  }
}
