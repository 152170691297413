<!-- <p-dropdown
  #dropdown
  [options]="value"
  [(ngModel)]="selectedValue"
  [optionLabel]="optionLabel"
  [showClear]="true"
>
</p-dropdown> -->

<ng-content></ng-content>

<div class="ion-action-sheet" *ngIf="dialogState != null">
  <div
    [@backdropFade]="dialogState"
    (@backdropFade.done)="endState($event)"
    class="backdrop"
    #backdrop
    (click)="close()"
  ></div>
  <div tabindex="0" class="sc-ion-action-sheet-md"></div>
  <div
    #dialog
    [@dialogOpen]="dialogState"
    class="action-sheet-wrapper ion-overlay-wrapper sc-ion-action-sheet-md"
    role="dialog"
  >
    <div class="action-sheet-container sc-ion-action-sheet-md">
      <div class="dropdown-container">
        <div class="p-px-3">
          <div class="close-icon-container">
            <i class="pi pi-times close-icon" (click)="close()"></i>
          </div>
          <button
            type="button"
            class="p-pl-0 action-sheet-button ion-activatable ion-focusable action-sheet-selected select-interface-option md sc-ion-action-sheet-md disabled"
          >
            <span
              class="fw-normal title-color action-sheet-button-inner sc-ion-action-sheet-md"
              >{{ headerText }}</span
            >
          </button>

          <div class="info-section-line p-mt-3"></div>
        </div>

        <div class="action-sheet-group sc-ion-action-sheet-md">
          <div
            *ngFor="let rowData of value; let i = index"
            class="p-d-flex p-flex-column layout-sheet"
          >
            <div
              (click)="selectValue(rowData, $event)"
              *ngIf="!isAccountListDropdown"
            >
              <div class="p-d-flex p-flex-row layout-ac">
                <div class="text-option">{{ rowData | displayValue }}</div>
                <!-- <img
                  *ngIf="rowData === selectedValue"
                  src="/assets/images/foundation-icon-simple-system-tick@3x.png"
                  width="24"
                  height="24"
                  alt=""
                  class="tick"
                /> -->
                <i
                  *ngIf="rowData === selectedValue"
                  class="pi pi-check tick"
                ></i>
              </div>
            </div>

            <div
              (click)="selectValue(rowData, $event)"
              *ngIf="isAccountListDropdown"
              class="payment-acc-list"
            >
              <div class="p-d-flex p-ai-center p-grid">
                <div class="p-d-flex p-ai-center p-col-2 p-py-0">
                  <img
                    *ngIf="rowData.acctTypePaotang === 'Y'; else elseBlock"
                    src="assets/images/E-saving.png"
                    alt=""
                    width="32"
                  />
                  <ng-template #elseBlock>
                    <img src="assets/images/ktb-icon.png" alt="" width="32" />
                  </ng-template>
                </div>
                <div class="p-col-9 p-p-0 min-h-50px">
                  <div class="title" id="divProductShelfAppInprogressMenuTitle">
                    {{ rowData | displayValue }}
                  </div>
                  <div
                    class="description"
                    id="divProductShelfAppInprogressMenuDescription"
                  >
                    <div>
                      {{ rowData.acctChannelDesc }}
                      {{ rowData.acctNo | acctNoFormat }}
                    </div>
                    <div
                      *ngIf="rowData.isRestriction === 'Y'"
                      class="red-alert"
                    >
                      ไม่สามารถใช้บัญชีนี้ได้ กรุณาติดต่อสาขา
                    </div>
                  </div>

                  <!-- <img
                    *ngIf="rowData === selectedValue"
                    src="/assets/images/foundation-icon-simple-system-tick@3x.png"
                    width="24"
                    height="24"
                    alt=""
                    class="tick-mark"
                  /> -->
                  <i
                    *ngIf="rowData === selectedValue"
                    class="pi pi-check tick-mark"
                  ></i>
                </div>
              </div>
            </div>

            <div *ngIf="i < value.length - 1" class="info-section-line"></div>
          </div>
          <div
            class="h-40px action-sheet-button ion-activatable ion-focusable action-sheet- select-interface-option md sc-ion-action-sheet-md disabled"
          ></div>
        </div>

        <!-- <button
          type="button"
          class="action-sheet-button ion-activatable ion-focusable action-sheet- select-interface-option md sc-ion-action-sheet-md"
        >
          <span class="action-sheet-button-inner sc-ion-action-sheet-md"
            >Mute for a week</span
          ></button
        ><button
          type="button"
          class="action-sheet-button ion-activatable ion-focusable action-sheet- select-interface-option md sc-ion-action-sheet-md"
        >
          <span class="action-sheet-button-inner sc-ion-action-sheet-md"
            >Mute for a year</span
          >
        </button>
         -->
      </div>
      <!-- <div
        class="action-sheet-group action-sheet-group-cancel sc-ion-action-sheet-md"
      >
        <button
          type="button"
          (click)="close()"
          class="action-sheet-button ion-activatable ion-focusable action-sheet-cancel sc-ion-action-sheet-md"
        >
          <span class="action-sheet-button-inner sc-ion-action-sheet-md">{{
            cancelText
          }}</span>
        </button>
      </div> -->
    </div>
  </div>
</div>
