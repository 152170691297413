import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { IApplications, ICasaAccountInquiry } from '../../interfaces';
import { HttpService } from '@web-di-ld/apps/http';
import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentDetailsService {
  private applications$ = new BehaviorSubject<any>({});
  selectedApplication$ = this.applications$.asObservable();

  constructor(private httpService: HttpService) {}

  async getCasaAccountInquiry() {
    return this.httpService
      .post(
        `/lfm-offers/app/v1/paotang/casa-account-inquiry`,
        {
          accept: 1,
        },
        false
      )
      .toPromise()
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }
}
