<ng-container *transloco="let t">
  <div class="container">
    <div class="p-d-flex p-ai-center p-jc-center p-d-flex-col">
      <div>
        <img src="assets/images/Upload.png" alt="" width="240px" />
      </div>
      <h3 class="p-m-0 font-20 fw-bold" id="divApplicationStatusValue">
        {{ selectedData?.application?.statusDesc }}
      </h3>
      <p
        class="information-message p-mt-2 p-text-center p-mb-3"
        id="divApplicationStatusNotification"
      >
        {{ selectedData?.application?.statusRemark }}
      </p>
    </div>
    <div class="info-section-line"></div>

    <div class="p-mb-4">
      <h4 class="p-mb-2 p-mt-3">{{ t('pendingUploadDocuments.title') }}</h4>
      <ul class="documents-list" [ngClass]="{ 'dislay-all': isCollapse }">
        <li
          [ngClass]="{ 'dislay-none': documentCount > 3 && i > 2 }"
          *ngFor="
            let document of pendingDocumentsList?.documentType;
            let i = index
          "
          class="list-item"
        >
          {{ document.doc_name }}
        </li>
      </ul>
      <div *ngIf="documentCount > 3">
        <div class="info-section-line p-mt-3 p-mb-2"></div>
        <div class="p-mb-2 button-link">
          <p-button
            (click)="toggleDocList()"
            *ngIf="!isCollapse"
            icon="pi pi-angle-down fix-position"
            iconPos="right"
            label="{{ t('pendingUploadDocuments.viewMore') }}"
            styleClass="p-button-link"
          >
          </p-button>
          <p-button
            (click)="toggleDocList()"
            *ngIf="isCollapse"
            icon="pi pi-angle-up fix-position"
            iconPos="right"
            label="{{ t('pendingUploadDocuments.short') }}"
            styleClass="p-button-link fw-bold"
          >
          </p-button>
        </div>
      </div>
    </div>

    <!-- RECEIVE DUE DATE -->

    <div class="info-received-card p-p-3 p-mb-2">
      <div class="p-d-flex p-justify-between">
        <div>
          <div class="info-label p-pb-0">
            {{ t('appStatus.uploadedByDate') }}
          </div>
          <div class="info-heading non-padding font-18">
            {{
              selectedData?.application?.followupExpireDate
                | customThDate: 'd MMM yy'
            }}
            <!-- {{ selectedData?.application?.followupExpireDate | date: 'HH:mm' }} -->
          </div>
        </div>
        <div
          *ngIf="selectedData?.application?.followupExpireCountdown"
          class="p-d-flex"
        >
          <div
            class="arrive-day p-d-flex p-align-center p-px-2 p-py-1 align-end"
          >
            <img src="assets/images/ic_pending.svg" alt="" width="16px" />
            <div class="p-pl-2">
              {{ selectedData?.application?.followupExpireCountdown }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isResubmitDocument">
        <div class="info-section-line p-mt-3 p-mb-2"></div>
        <div class="button-link" *ngIf="isExtendUploadDocument === false">
          <p-button
            (click)="openExtendLoanDialog()"
            label="{{ t('appStatus.ExtendUploadDocuments') }}"
            styleClass="p-button-link fw-bold"
          >
          </p-button>
        </div>
        <div
          *ngIf="isExtendUploadDocument === true"
          class="p-d-flex p-jc-center p-align-center color-838383"
        >
          <div class="mt-5px">
            <img
              src="assets/images/ic_check-circle-gray.png"
              alt=""
              width="16px"
              height="16px"
            />
          </div>
          <div class="p-pl-1 extend-text">ขยายเวลาอัปโหลดเอกสารแล้ว</div>
        </div>
      </div>
    </div>
  </div>

  <div class="divider"></div>
  <div class="app-details">
    <h4 class="p-mb-3 p-mt-2">
      {{ t('pendingUploadDocuments.productTitle') }}
    </h4>
    <div class="p-grid p-grid-card">
      <div class="p-col-4 info-label" id="divApplicationStatusProductNameText">
        {{ t('appStatus.product') }}
      </div>
      <div class="p-col-8 info-value" id="divApplicationStatusProductNameValue">
        {{ selectedData?.application?.productName }}
      </div>
      <div class="p-col-4 info-label" id="divApplicationStatusFullNameText">
        {{ t('appStatus.fullName') }}
      </div>
      <div class="p-col-8 info-value" id="divApplicationStatusFullNameValue">
        <span *ngIf="getCurrentLang === 'th'">{{
          selectedData?.customer?.fullNameTh
        }}</span>
        <span *ngIf="getCurrentLang === 'en'">{{
          selectedData?.customer?.fullNameTh
        }}</span>
      </div>
    </div>
    <div class="info-section-line p-mt-2 p-mb-3"></div>

    <!-- OTHER LAYOUT -->
    <div>
      <div class="p-grid p-grid-card">
        <div class="p-col-6 info-label" id="divApplicationStatusLoanAmountText">
          {{ t('appStatus.requestLoanAmount') }}
        </div>
        <div
          class="p-col-6 info-value"
          id="divApplicationStatusLoanAmountValue"
        >
          <span class="loan-amount">{{
            parseToDecimal(selectedData?.application?.requestCreditLimit)
          }}</span>
          <span *ngIf="getCurrentLang === 'th'"> บาท </span>
          <span *ngIf="getCurrentLang === 'en'"> THB </span>
        </div>
        <div
          class="p-col-6 info-label"
          id="divApplicationStatusInterestRateText"
        >
          {{ t('appStatus.interestRate') }}
        </div>
        <div
          class="p-col-6 info-value loan-interest"
          id="divApplicationStatusInterestRateValue"
        >
          {{ selectedData?.application?.offerMaxInterestRate }}%
          <span *ngIf="getCurrentLang === 'th'"> ต่อปี </span>
          <span *ngIf="getCurrentLang === 'en'"> per year </span>
        </div>

        <div
          class="p-col-6 info-label"
          id="divApplicationStatusInstallementText"
        >
          {{ t('appStatus.installment') }} <br />
          {{ t('appStatus.estimates') }}
        </div>
        <div
          class="p-col-6 info-value"
          id="divApplicationStatusInstallementValue"
        >
          <span class="loan-amount fw-normal">{{
            parseToDecimal(selectedData?.application?.maxInstallmentAmount)
          }}</span>
          <span *ngIf="getCurrentLang === 'th'"> บาท </span>
          <span *ngIf="getCurrentLang === 'en'"> THB </span>
        </div>

        <div
          class="p-col-6 info-label"
          id="divApplicationStatusPaymentTermText"
        >
          {{ t('appStatus.paymentTerms') }}
        </div>
        <div
          class="p-col-6 info-value"
          id="divApplicationStatusPaymentTermValue"
        >
          {{ selectedData?.application?.selectedPaymentTerm }}
          <span
            *ngIf="selectedData?.application?.selectedPaymentTermType === 'M'"
          >
            <span *ngIf="getCurrentLang === 'th'">
              {{ paymentTermEnum.MONTHS_TH }}
            </span>
            <span *ngIf="getCurrentLang === 'en'">
              {{ paymentTermEnum.MONTHS_EN }}
            </span>
          </span>
        </div>
        <div
          class="p-col-12 info-label pt-c"
          id="divApplicationStatusDescriptionValue"
        >
          <div class="info-start">
            <img
              class="p-mr-2 info-icon"
              src="/assets/images/foundation-icon-simple-system-information.svg"
              alt=""
              width="16px"
            />
          </div>
          <div>{{ t('appStatus.installmentAmountPerMonth') }}</div>
        </div>
      </div>
      <div class="info-section-line p-mb-2 p-mt-2"></div>

      <div class="p-mb-2 button-link" *ngIf="!isListCollapse">
        <p-button
          (click)="toggleList()"
          icon="pi pi-angle-down fix-position"
          iconPos="right"
          label="{{ t('appStatus.seeMore') }}"
          styleClass="p-button-link"
          id="seeMoreBtn"
        >
        </p-button>
      </div>

      <div *ngIf="isListCollapse">
        <div class="p-grid p-grid-card p-mt-3 p-mb-2">
          <div class="p-col-6 info-label" id="divApplicationStatusFeeText">
            {{ t('appStatus.fee') }}
          </div>
          <div class="p-col-6 info-value" id="divApplicationStatusFeeValue">
            {{ t('appStatus.percent') }}
            {{ t('appStatus.feeDescription') }}
          </div>
        </div>
        <div class="info-section-line p-mb-3"></div>
        <div class="p-grid p-grid-card p-mb-2">
          <div class="p-col-6 info-label" id="divApplicationStatusDateText">
            {{ t('appStatus.applicationDate') }}
          </div>
          <div class="p-col-6 info-value" id="divApplicationStatusDateValue">
            {{
              selectedData?.application?.submitDate
                | customThDate: 'd MMM yy':getCurrentLang
            }}
            {{ selectedData?.application?.submitDate | date: 'HH:mm' }}
          </div>
        </div>
        <div class="p-d-flex p-jc-between p-ai-center">
          <div class="info-label" id="divApplicationStatusRefNoText">
            {{ t('appStatus.refNo') }}
          </div>
          <div class="info-value" id="divApplicationStatusRefNoValue">
            {{ selectedData?.application?.applicationRef }}
          </div>
        </div>
        <div class="info-section-line"></div>
      </div>

      <div class="p-mb-2 button-link" *ngIf="isListCollapse">
        <p-button
          (click)="toggleList()"
          icon="pi pi-angle-up fix-position"
          iconPos="right"
          label="{{ t('appStatus.short') }}"
          styleClass="p-button-link"
          id="seeMoreBtn"
        >
        </p-button>
      </div>

      <div class="p-grid p-grid-card info-alert-card p-mt-2">
        <div
          class="p-col-12 info-label"
          id="divApplicationStatusDescriptionValue"
        >
          <div class="info-start">
            <img
              class="p-mr-2 info-icon"
              src="/assets/images/foundation-icon-simple-system-information-yellow.svg"
              alt=""
              width="16px"
            />
          </div>
          <div class="info-alert-text">
            {{ t('pendingUploadDocuments.alertInfo') }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="button-container">
    <div class="p-mb-3">
      <web-di-ld-button
        [label]="t('appStatus.viewDocumentBtn')"
        (clickEvt)="openFollowup()"
      ></web-di-ld-button>
    </div>
    <div class="p-mb-2 button-link">
      <p-button
        (click)="cancelApplication()"
        label="{{ t('appStatus.cancelApplicationBtn') }}"
        styleClass="p-button-link underline"
      >
      </p-button>
    </div>
  </div>

  <ng-container>
    <p-dialog
      class="dialog-container"
      [(visible)]="extendLoanDialogVisible"
      [modal]="true"
      [showHeader]="false"
    >
      <div class="content-container p-mt-2">
        <h3 class="title">
          {{ t('appStatus.extendLoanDialog.title') }}
        </h3>
        <p class="info">
          {{
            t('appStatus.extendLoanDialog.description', {
              extendDay: pendingDocumentsList?.extendDay
            })
          }}
        </p>
        <div class="p-mt-4 p-grid">
          <div class="p-col-6 cancel_btn">
            <web-di-ld-button
              label="{{ t('appStatus.extendLoanDialog.cancelBtn') }}"
              (clickEvt)="cancelExtendLoanDialog()"
            >
            </web-di-ld-button>
          </div>
          <div class="p-col-6">
            <web-di-ld-button
              label="{{ t('appStatus.extendLoanDialog.extendTimeBtn') }}"
              (clickEvt)="extendConfirm()"
            >
            </web-di-ld-button>
          </div>
        </div>
      </div>
    </p-dialog>
  </ng-container>
</ng-container>
