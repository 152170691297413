import { DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { pathUrl } from 'apps/di-ld/src/app/configs/pathUrl';
import { IPendingDocuments } from 'apps/di-ld/src/app/core/interfaces';
import {
  JsInterfaceService,
  ApplicationsService,
} from 'apps/di-ld/src/app/core/services';
import { PendingDocumentsService } from 'apps/di-ld/src/app/core/services/pending-documents/pending-documents.service';
import dayjs from 'dayjs';
import {
  CANCEL_FLOW,
  PAYMENT_TYPE_TERM,
} from '../../../../../core/constant/base.enum';
import { AppStateService } from 'apps/di-ld/src/app/app-state.service';
import {
  GA_EVENT_ACTION,
  GA_EVENT_CATEGORY,
  GA_CUSTOM_DIMENSION,
} from 'apps/di-ld/src/app/core/constant/base.enum';
import {
  FirebaseParam,
  EventGATagging,
} from 'apps/di-ld/src/app/core/interfaces/js-interface.interface';

@Component({
  selector: 'web-di-ld-status-pending-document',
  templateUrl: './status-pending-document.component.html',
  styleUrls: ['./status-pending-document.component.scss'],
})
export class StatusPendingDocumentComponent implements OnInit {
  @Input() selectedData!: any;
  @Input() applicationRef!: string;
  @Input() getCurrentLang?: string;

  @Output() onRefresh = new EventEmitter();

  public paymentTermEnum = PAYMENT_TYPE_TERM;
  pendingDocumentsList?: IPendingDocuments;
  isCollapse?: boolean;
  isListCollapse?: boolean;
  documentCount: number = 0;
  extendLoanDialogVisible?: boolean;
  isResubmitDocument = false;

  isExtendUploadDocument = false;

  readonly decimalPipe = new DecimalPipe(this._locale);

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private pendingDocumentsService: PendingDocumentsService,
    private jsInterfaceService: JsInterfaceService,
    private router: Router,
    private appState: AppStateService,
    private applicationsService: ApplicationsService
  ) {}

  ngOnInit(): void {
    if (this.pendingDocumentsList?.loanAppId == null) {
      this.getDocList();
    }
    if (this.selectedData?.application?.followupExtendDate) {
      this.isExtendUploadDocument = true;
    }
    if (
      this.selectedData?.application?.status_internal === 'RESUBMIT_DOCUMENT'
    ) {
      this.isResubmitDocument = true;
    }
    this.applicationsService.setGaTaggingBasedOnStatus(
      GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD
    );
    const event = {
      action: GA_EVENT_ACTION.VIEW,
      category: GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD,
      label: '',
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
    ];
    this.gaTagging(event, dimension);
  }

  async getDocList() {
    if (!this.applicationRef) {
      this.applicationRef = this.appState.applicationRef;
    }
    this.pendingDocumentsList = await this.pendingDocumentsService.getDocList(
      this.applicationRef
    );
    this.documentCount = this.pendingDocumentsList?.documentType?.length;
  }

  toggleDocList() {
    this.isCollapse = !this.isCollapse;
  }

  toggleList() {
    this.isListCollapse = !this.isListCollapse;
    if (this.isListCollapse) {
      const event = {
        action: GA_EVENT_ACTION.CLICK,
        category: GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD,
        label: `${GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD}_Seemore`,
        value: '',
      };
      const dimension = [
        {
          key: GA_CUSTOM_DIMENSION.KEY,
          value: this.selectedData.application.productType,
        },
      ];
      this.gaTagging(event, dimension);
    }
  }

  openExtendLoanDialog() {
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD,
      label: `${GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD}_Extend`,
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
    ];
    this.gaTagging(event, dimension);
    this.extendLoanDialogVisible = true;
  }

  cancelExtendLoanDialog() {
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD,
      label: `${GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD}_ExtendCN`,
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
    ];
    this.gaTagging(event, dimension);
    this.extendLoanDialogVisible = false;
  }

  async extendConfirm() {
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD,
      label: `${GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD}_ExtendCF`,
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
    ];
    this.gaTagging(event, dimension);
    const response = await this.pendingDocumentsService.extendFollowup(
      this.applicationRef
    );
    if (response) {
      this.isExtendUploadDocument = true;
      this.onRefresh.emit();
    }
    this.extendLoanDialogVisible = false;
  }

  arriveDate(date?: string) {
    const dateArrive = dayjs(date);
    const dateNow = dayjs();
    const arriveDate = dateArrive.diff(dateNow, 'day');
    return arriveDate === -1 ? '' : arriveDate + 1;
  }

  cancelApplication() {
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD,
      label: `${GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD}_CancelLoan`,
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
    ];
    this.gaTagging(event, dimension);
    this.doCancel();
  }

  parseToDecimal(value: any) {
    return this.decimalPipe.transform(value, '1.2-2');
  }

  openFollowup() {
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD,
      label: `${GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD}_Upload`,
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
    ];
    console.log('🚀 ~ this.selectedData:', this.selectedData);
    console.log('🚀 ~ this.application:', this.selectedData.application);
    console.log(
      '🚀 ~ this.productType:',
      this.selectedData.application.productType
    );
    this.gaTagging(event, dimension);
    //  GA TAGGING WILL NOT SHOW ON LOG WHEN OPEN FOLLOWUP
    this.jsInterfaceService.openFollowup();
  }

  doCancel() {
    this.router.navigate([pathUrl.turndown], {
      state: {
        applicationRef: this.applicationRef,
        from: CANCEL_FLOW.FROM_UPLOAD_DOCUMENT_FLOW,
      },
    });
  }

  gaTagging(event: EventGATagging, dimension?: FirebaseParam[]) {
    this.jsInterfaceService.gaTagging(event, dimension);
  }
}
