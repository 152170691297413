import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { defer, EMPTY, forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  timeout,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from './http.service';
import { environment } from '@environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token$ = this.httpService.token$;

  constructor(private httpService: HttpService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /**
     * http to json file
     * no add header Authentication
     */
    // const isJsonFile = request.url.includes('json');
    // if (isJsonFile) {
    //   return next.handle(request);
    // }
    /**
     * other http
     * use header Authentication
     */
    return this.token$.pipe(
      switchMap((token) => {
        const verifyToken = this.httpService.verifyToken;
        let headers = request.headers;
        if (
          /lfm\-\w+(?=\/)/.test(request.url) ||
          (request.url.includes('/lfm-access-channel') &&
            request.url.includes('/exchange-token-paotang'))
        ) {
          if (/pin-verify/.test(request.url)) {
            headers = headers.append(
              'Authorization',
              `Bearer ${verifyToken?.data.accessToken}`
            );
            headers = headers.set('Accept-Language', 'th-TH');
          } else if (/verify/.test(request.url)) {
            headers = headers.append('Authorization', `Bearer ${token}`);
          } else {
            headers = headers.append(
              'Authorization',
              `Bearer ${verifyToken?.data.accessToken}`
            );
            headers = headers.set('Accept-Language', 'th-TH');
          }
        }
        request.urlWithParams.concat;
        let url = request.url;
        if (/demography/.test(request.url) || /geography/.test(request.url)) {
          url = url.concat(
            (url.includes('?') ? '&' : '?') +
              'language=' +
              this.httpService.acceptLang
          );

          if (environment.noCache) {
            const uuid = this.generateUUID();
            url = url.concat(
              (url.includes('?') ? '&' : '?') + 'noCache=' + uuid
            );
          }
        }

        const appliedReq = request.clone({
          headers,
          url: url,
        });
        return next.handle(appliedReq);
      })
    );
  }

  generateUUID() {
    let myuuid = uuidv4();
    return myuuid;

    // var d = new Date().getTime();
    // var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    //   /[xy]/g,
    //   function (c) {
    //     var r = (d + Math.random() * 16) % 16 | 0;
    //     d = Math.floor(d / 16);
    //     return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    //   }
    // );
    // return uuid;
  }
}
