import { DecimalPipe } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from 'apps/di-ld/src/app/app-state.service';
import { pathUrl } from 'apps/di-ld/src/app/configs';
import {
  GA_CUSTOM_DIMENSION,
  GA_EVENT_ACTION,
  GA_EVENT_CATEGORY,
  PAYMENT_TYPE_TERM,
} from 'apps/di-ld/src/app/core/constant/base.enum';
import { IPendingDocuments } from 'apps/di-ld/src/app/core/interfaces';
import {
  FirebaseParam,
  EventGATagging,
} from 'apps/di-ld/src/app/core/interfaces/js-interface.interface';
import {
  ApplicationsService,
  JsInterfaceService,
  PendingDocumentsService,
} from 'apps/di-ld/src/app/core/services';
import dayjs from 'dayjs';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'web-di-ld-status-consider',
  templateUrl: './status-consider.component.html',
  styleUrls: ['./status-consider.component.scss'],
})
export class StatusConsiderComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/animation/pending-icon/pending-icon.json',
  };
  @Input() selectedData!: any;
  @Input() getCurrentLang?: string;
  @Input() applicationRef!: string;

  public paymentTermEnum = PAYMENT_TYPE_TERM;
  readonly decimalPipe = new DecimalPipe(this._locale);
  pendingDocumentsList?: IPendingDocuments;
  documentCount: number = 0;
  isCollapse?: boolean;

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private jsInterfaceService: JsInterfaceService,
    private appState: AppStateService,
    private pendingDocumentsService: PendingDocumentsService,
    private router: Router,
    private applicationsService: ApplicationsService
  ) {}

  ngOnInit(): void {
    this.applicationsService.setGaTaggingBasedOnStatus(
      GA_EVENT_CATEGORY.PENDING_APPROVE
    );
    const event = {
      action: GA_EVENT_ACTION.VIEW,
      category: GA_EVENT_CATEGORY.PENDING_APPROVE,
      label: '',
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
    ];
    this.gaTagging(event, dimension);
    this.getDocList();
  }

  async getDocList() {
    if (!this.applicationRef) {
      this.applicationRef = this.appState.applicationRef;
    }
    this.pendingDocumentsList = await this.pendingDocumentsService.getDocList(
      this.applicationRef
    );
    this.documentCount = this.pendingDocumentsList?.documentType?.length;
  }

  toggleDocList() {
    this.isCollapse = !this.isCollapse;
  }

  arriveDate(date?: string) {
    const dateArrive = dayjs(date);
    const dateNow = dayjs();
    const arriveDate = dateArrive.diff(dateNow, 'day');
    return arriveDate + 1;
  }

  parseToDecimal(value: any) {
    return this.decimalPipe.transform(value, '1.2-2');
  }

  gaTagging(event: EventGATagging, dimension?: FirebaseParam[]) {
    this.jsInterfaceService.gaTagging(event, dimension);
  }

  back() {
    this.router.navigate([pathUrl.appInprogress]);
  }
}
