<div class="container">
  <ng-container *transloco="let t">
    <div class="p-d-flex p-ai-center p-jc-center p-d-flex-col">
      <img src="assets/images/app-detail-success.png" alt="" width="240px" />
      <h3 class="p-m-0" id="divApplicationStatusValue">
        {{ selectedData?.application?.statusDesc }}
      </h3>
      <p
        class="information-message p-mt-2 p-text-center"
        id="divApplicationStatusNotification"
      >
        {{ selectedData?.application?.statusRemark }}
      </p>
    </div>
    <div class="app-details">
      <div class="info-section-line p-mb-3"></div>
      <div class="p-grid p-grid-card">
        <div
          class="p-col-4 info-label first-col"
          id="divApplicationStatusProductNameText"
        >
          {{ t('appStatus.product') }}
        </div>
        <div
          class="p-col-8 info-value first-col"
          id="divApplicationStatusProductNameValue"
        >
          {{ selectedData?.application?.productName }}
        </div>
        <div
          class="p-col-4 info-label p-pb-0"
          id="divApplicationStatusFullNameText"
        >
          {{ t('appStatus.fullName') }}
        </div>
        <div
          class="p-col-8 info-value p-pb-0"
          id="divApplicationStatusFullNameValue"
        >
          <span *ngIf="getCurrentLang === 'th'">{{
            selectedData?.customer?.fullNameTh
          }}</span>
          <span *ngIf="getCurrentLang === 'en'">{{
            selectedData?.customer?.fullNameTh
          }}</span>
        </div>
      </div>
      <div class="info-section-line p-my-3"></div>
      <div class="p-grid p-grid-card">
        <div
          class="p-col-6 info-label first-col"
          id="divApplicationStatusLoanAmountText"
        >
          {{ t('appStatus.creditApproved') }}
        </div>
        <div
          class="p-col-6 info-value first-col"
          id="divApplicationStatusLoanAmountValue"
        >
          <span class="loan-amount">{{
            parseToDecimal(selectedData?.application?.actualCreditLimit)
          }}</span>
          <span *ngIf="getCurrentLang === 'th'"> บาท </span>
          <span *ngIf="getCurrentLang === 'en'"> THB </span>
        </div>
        <div
          class="p-col-6 info-label p-pb-0"
          id="divApplicationStatusInterestRateText"
        >
          {{ t('appStatus.interestRate') }}
        </div>
        <div
          class="p-col-6 info-value loan-interest p-pb-0"
          id="divApplicationStatusInterestRateValue"
        >
          {{ selectedData?.application?.offerMaxInterestRate }}%
          <span *ngIf="getCurrentLang === 'th'"> ต่อปี </span>
          <span *ngIf="getCurrentLang === 'en'"> per year </span>
        </div>
        <div class="info-section-line p-my-3"></div>
        <div class="p-col-12 info-heading">
          {{ t('appStatus.paymentDescription') }}
        </div>
        <div
          class="p-col-6 info-label first-col"
          id="divApplicationStatusInstallementText"
        >
          {{ t('appStatus.installment') }}
        </div>
        <div
          class="p-col-6 info-value first-col"
          id="divApplicationStatusInstallementValue"
        >
          <span class="loan-amount">{{
            parseToDecimal(selectedData?.application?.finalMonthlyInstallment)
          }}</span>
          <span *ngIf="getCurrentLang === 'th'"> บาท </span>
          <span *ngIf="getCurrentLang === 'en'"> THB </span>
        </div>

        <div
          class="p-col-6 info-label"
          id="divApplicationStatusPaymentTermText"
        >
          {{ t('appStatus.paymentTerms') }}
        </div>
        <div
          class="p-col-6 info-value"
          id="divApplicationStatusPaymentTermValue"
        >
          {{ selectedData?.application?.actualPaymentTerm }}
          <span
            *ngIf="selectedData?.application?.selectedPaymentTermType === 'M'"
          >
            <span *ngIf="getCurrentLang === 'th'">
              {{ paymentTermEnum.MONTHS_TH }}
            </span>
            <span *ngIf="getCurrentLang === 'en'">
              {{ paymentTermEnum.MONTHS_EN }}
            </span>
          </span>
        </div>
        <div class="p-col-6 info-label">เงื่อนไขการชำระ</div>
        <div class="p-col-6 info-value">
          ชำระทุกวันที่
          {{ selectedData.application?.paymentDueDate }} ของทุกเดือน
        </div>
        <div class="p-col-6 info-label">วันผ่อนชำระงวดแรก</div>
        <div class="p-col-6 info-value">
          {{
            selectedData.application?.firstPaymentDueDate
              | customThDate: 'd MMM yy'
          }}
        </div>
        <div class="p-col-6 info-label p-pb-0">วันผ่อนชำระงวดสุดท้าย</div>
        <div class="p-col-6 info-value p-pb-0">
          {{
            selectedData.application?.maturityDate | customThDate: 'd MMM yy'
          }}
        </div>
      </div>
      <div class="info-section-line p-my-3"></div>
      <div class="p-grid p-grid-card">
        <div
          class="p-col-6 info-label first-col"
          id="divApplicationStatusFeeText"
        >
          {{ t('appStatus.fee') }}
        </div>
        <div
          class="p-col-6 info-value first-col"
          id="divApplicationStatusFeeValue"
        >
          {{ t('appStatus.percent') }}<br />
          {{ t('appStatus.feeDescription') }}
        </div>
        <div
          class="p-col-12 info-label p-pb-0"
          id="divApplicationStatusDescriptionValue"
        >
          <div class="info-start">
            <img
              class="p-mr-2"
              src="/assets/images/foundation-icon-simple-system-information.svg"
              alt=""
              width="16px"
            />
          </div>
          <div>
            {{ t('appStatus.installmentFeeAlert') }}
          </div>
        </div>
      </div>
      <div class="info-section-line p-my-3"></div>
      <div class="p-grid p-grid-card">
        <div class="p-col-12 info-heading">รายละเอียดบัญชีสินเชื่อ</div>
        <div class="p-col-6 info-label first-col">บัญชีรับเงินสินเชื่อ</div>
        <div class="p-col-6 info-value first-col">
          {{
            selectedData.application?.disbursementAcctNo | accountNumberFormat
          }}
        </div>
        <div class="p-col-6 info-label p-pb-0">
          บัญชีตัดยอดผ่อนชำระอัตโนมัติ
        </div>
        <div class="p-col-6 info-value p-pb-0">
          {{
            selectedData.application?.directDebitAcctNo | accountNumberFormat
          }}
        </div>
      </div>
      <div class="info-section-line p-my-3"></div>
      <div class="p-grid p-grid-card">
        <div
          class="p-col-6 info-label first-col"
          id="divApplicationStatusDateText"
        >
          วันที่ยอมรับสัญญา
        </div>
        <div
          class="p-col-6 info-value first-col"
          id="divApplicationStatusDateValue"
        >
          {{
            selectedData.application?.acceptedDate | customThDate: 'd MMM yy'
          }}
        </div>
        <div class="p-col-6 info-label" id="divApplicationStatusDateText">
          วันที่ได้รับเงินสินเชื่อ
        </div>
        <div class="p-col-6 info-value" id="divApplicationStatusDateValue">
          {{
            selectedData.application?.receiptLoanDate | customThDate: 'd MMM yy'
          }}
        </div>
      </div>
      <div class="p-d-flex p-jc-between p-ai-center">
        <div class="info-label p-pb-0" id="divApplicationStatusRefNoText">
          {{ t('appStatus.refNo') }}
        </div>
        <div class="info-valuep-pb-0" id="divApplicationStatusRefNoValue">
          {{ selectedData?.application?.applicationRef }}
        </div>
      </div>
      <div class="p-grid p-grid-card info-alert-card p-mt-3">
        <div
          class="p-col-12 info-label"
          id="divApplicationStatusDescriptionValue"
        >
          <div class="info-start">
            <img
              class="p-mr-2"
              src="/assets/images/foundation-icon-simple-system-information-yellow.svg"
              alt=""
              width="16px"
            />
          </div>
          <div
            [innerHTML]="
              t('appStatus.notifyAcceptDialog', {
                email: selectedData?.customer?.email
              })
            "
            class="info-alert-text"
          ></div>
        </div>
      </div>

      <!-- DONE BUTTON -->
      <div class="p-mt-4">
        <web-di-ld-button
          #nextButton
          id="divDoneApplication"
          refId="BtnNcbConsent"
          label="{{ t('appStatus.done') }}"
          [type]="'button'"
          (clickEvt)="onDone()"
        ></web-di-ld-button>
      </div>
    </div>
  </ng-container>
</div>
