import { Component, Input, OnInit } from '@angular/core';
import {
  APPLICATION_STATUS,
  PAYMENT_TYPE_TERM,
} from 'apps/di-ld/src/app/core/constant/base.enum';
import {
  GA_EVENT_ACTION,
  GA_EVENT_CATEGORY,
  GA_CUSTOM_DIMENSION,
} from 'apps/di-ld/src/app/core/constant/base.enum';
import {
  FirebaseParam,
  EventGATagging,
} from 'apps/di-ld/src/app/core/interfaces/js-interface.interface';
import {
  JsInterfaceService,
  ApplicationsService,
} from 'apps/di-ld/src/app/core/services';

@Component({
  selector: 'web-di-ld-status-unsuccess',
  templateUrl: './status-unsuccess.component.html',
  styleUrls: ['./status-unsuccess.component.scss'],
})
export class StatusUnsuccessComponent implements OnInit {
  @Input() selectedData!: any;
  @Input() getCurrentLang?: string;

  public paymentTermEnum = PAYMENT_TYPE_TERM;

  constructor(
    private jsInterfaceService: JsInterfaceService,
    private applicationsService: ApplicationsService
  ) {}

  ngOnInit(): void {
    this.applicationsService.setGaTaggingBasedOnStatus(
      GA_EVENT_CATEGORY.STATUS_UNSUCCESS
    );
    const event = {
      action: GA_EVENT_ACTION.VIEW,
      category: GA_EVENT_CATEGORY.STATUS_UNSUCCESS,
      label: '',
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
      {
        key: GA_CUSTOM_DIMENSION.PT_INFORMATION,
        value: `Status:${this.selectedData.application.status}`,
      },
    ];
    this.gaTagging(event, dimension);
  }

  isCanceled() {
    const status = this.selectedData?.application?.status_internal;
    return (
      status === APPLICATION_STATUS.CANCELED ||
      status === APPLICATION_STATUS.OFFER_TURNED_DOWN ||
      status === APPLICATION_STATUS.OFFER_TURNED_DOWN_FROM_PAOTANG
    );
  }

  isRejected() {
    const status = this.selectedData?.application?.status_internal;
    return (
      status === APPLICATION_STATUS.CANCELED_BY_BANK ||
      status === APPLICATION_STATUS.REJECTED_BY_COMPLIANCE ||
      status === APPLICATION_STATUS.REJECTED_BY_BANK ||
      status === APPLICATION_STATUS.REJECTED_BY_FOLLOWUP ||
      status === APPLICATION_STATUS.REJECTED_FAILED_SCREENING_FROM_PAOTANG ||
      status === APPLICATION_STATUS.REJECTED_FAILED_SCREENING
    );
  }

  isExpired() {
    const status = this.selectedData?.application?.status_internal;
    return (
      status === APPLICATION_STATUS.APPROVAL_EXPIRED ||
      status === APPLICATION_STATUS.REJECTED_MISSING_DOCUMENT
    );
  }

  gaTagging(event: EventGATagging, dimension?: FirebaseParam[]) {
    this.jsInterfaceService.gaTagging(event, dimension);
  }
}
