import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, throwError } from 'rxjs';

import { AppStateService } from '../../../app-state.service';
import { HttpService } from '@web-di-ld/apps/http';
import { AisEligible } from '../../interfaces/ais.interface';
import { AIS_ERROR_CODE, GA_CUSTOM_DIMENSION } from '../../constant/base.enum';
import { AisOtp } from '../../interfaces/ais-otp.interface';
import { FirebaseParam } from '../../interfaces/js-interface.interface';
import { LoanApplyFormComponent } from '../../../pages/loan-apply/components/loan-apply-form/loan-apply-form.component';

// service
@Injectable({
  providedIn: 'root',
})
export class AisService {
  public errorCode = '';
  public displayError = false;
  public otpRefCode = '';
  public otpIsLockTimeCountingDown = false;

  private otpResponse$ = new BehaviorSubject<any>(null);
  private resendTimeStamp$ = new BehaviorSubject<number>(0);
  private applyForm$ = new BehaviorSubject<LoanApplyFormComponent | null>(null);

  constructor(
    public appState: AppStateService,
    public httpService: HttpService
  ) {}

  setOtpResponse(response: any) {
    this.otpResponse$.next(response);
  }

  getOtpResponse() {
    return this.otpResponse$.getValue();
  }

  setResendTimeStamp(timeStamp: number) {
    this.resendTimeStamp$.next(timeStamp);
  }

  getResendTimeStamp() {
    return this.resendTimeStamp$.getValue();
  }

  clearOtpData() {
    this.otpResponse$.next(null);
    this.resendTimeStamp$.next(0);
  }

  public get otpFlag(): string {
    const flag = String(!this.otpIsLockTimeCountingDown);
    return flag.charAt(0).toUpperCase() + flag.slice(1);
  }

  public get productTypeDimension(): FirebaseParam {
    return {
      key: GA_CUSTOM_DIMENSION.KEY,
      value: this.appState.snapshot.loanProduct.productCode,
    };
  }

  setApplyForm(applyForm: LoanApplyFormComponent) {
    this.applyForm$.next(applyForm);
  }

  getApplyForm() {
    return this.applyForm$.getValue();
  }

  public get informationDimension(): FirebaseParam {
    const valueInformation: string[] = [
      `otp_flag:${this.otpFlag}`,
      ...(!this.otpIsLockTimeCountingDown
        ? [`ref_code:${this.otpRefCode}`]
        : []),
    ];

    return {
      key: GA_CUSTOM_DIMENSION.PT_INFORMATION,
      value: valueInformation.join(),
    };
  }

  getCheckEligible(): any {
    return this.httpService
      .get<AisEligible>(`/lfm-ais/app/v1/ais/check-eligible`)
      .toPromise()
      .then((response: any) => {
        if (response.code === '0') {
          if (response.data?.consentHistory) {
            // pass
          }
          return {
            code: '0',
            message: 'success',
            data: response.data
              ? {
                  eligible: response.data.eligible,
                  requireConsent: response.data.requireConsent,
                  consentHistory:
                    response.data.eligible && !response.data.requireConsent
                      ? {
                          answer: response.data.consentHistory.answer,
                          dateTime: response.data.consentHistory.dateTime,
                        }
                      : null,
                }
              : null,
          };
        } else {
          throw new Error('Unexpected response format');
        }
      })
      .catch((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 500) {
          if (error.error.code === AIS_ERROR_CODE.ELIGIBILITY_CHECK_FAILED) {
            return {
              code: AIS_ERROR_CODE.ELIGIBILITY_CHECK_FAILED,
              message: 'Consent version not match',
            };
          } else if (
            error.error.code === AIS_ERROR_CODE.CUSTOMER_PROFILE_NOT_FOUND
          ) {
            return {
              code: AIS_ERROR_CODE.CUSTOMER_PROFILE_NOT_FOUND,
              message: 'Not found customer profile',
            };
          }
        }
        this.handleError(error);
        return throwError('Something bad happened; please try again later.');
      });
  }

  getCustomerConsentOtp(): any {
    return this.httpService
      .get<AisOtp>(`/lfm-ais/app/v1/ais/customer-consent-otp`)
      .toPromise()
      .then((response: any) => {
        if (response.message === 'success' && response.code === '0') {
          return response;
        } else {
          throw new Error('Unexpected response format');
        }
      })
      .catch((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 500) {
          if (error.error.code === AIS_ERROR_CODE.BLOCKED_USER) {
            return {
              code: AIS_ERROR_CODE.BLOCKED_USER,
              message: 'blocked user',
              data: error.error.data,
            };
          }
          return {
            code: AIS_ERROR_CODE.CONSENT_CONFIRM_OTP_EXPIRED,
            message: 'blocked user',
            data: error.error.data,
          };
        } else {
          this.handleError(error);
          return throwError('Something bad happened; please try again later.');
        }
      });
  }

  postConsentConfirmOtp(pwd: string, transactionID: string): any {
    return this.httpService
      .post(`/lfm-ais/app/v1/ais/consent-confirm-otp`, { pwd, transactionID })
      .toPromise()
      .then((response: any) => {
        if (
          response.message === 'success' &&
          response.code === '0' &&
          response.data.description === 'SUCCESS'
        ) {
          return response;
        } else {
          throw new Error('Unexpected response format');
        }
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  getConsentProfile(): any {
    return this.httpService
      .get(`/lfm-ais/app/v1/ais/consent-profile`)
      .toPromise()
      .then((response: any) => {
        if (response.message === 'success' && response.code === '0') {
          return response;
        } else {
          throw new Error('Unexpected response format');
        }
      })
      .catch((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 500) {
          if (error.error.code === AIS_ERROR_CODE.TOKEN_CONSENT_PROFILE_EMPTY) {
            return {
              code: AIS_ERROR_CODE.TOKEN_CONSENT_PROFILE_EMPTY,
              message: 'token consent profile is empty',
            };
          } else if (
            error.error.code === AIS_ERROR_CODE.CONSENT_VERSION_NOT_MATCH
          ) {
            return {
              code: AIS_ERROR_CODE.CONSENT_VERSION_NOT_MATCH,
              message: 'Consent version not match',
            };
          }
        }
        this.handleError(error);
        return throwError('Something bad happened; please try again later.');
      });
  }

  postConsentAgreement(consentId: string, acceptFlag: boolean): any {
    const agreementItems = {
      agreementItems: [
        { privacySpecificationId: consentId, acceptFlag: acceptFlag },
      ],
    };
    return this.httpService
      .post(`/lfm-ais/app/v1/ais/consent-agreement`, agreementItems)
      .toPromise()
      .then((response: any) => {
        if (response.message === 'success' && response.code === '0') {
          return response;
        } else {
          throw new Error('Unexpected response format');
        }
      })
      .catch((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 500) {
          if (error.error.code === AIS_ERROR_CODE.TOKEN_CONSENT_PROFILE_EMPTY) {
            return {
              code: AIS_ERROR_CODE.TOKEN_CONSENT_PROFILE_EMPTY,
              message: 'token consent profile is empty',
            };
          }
        }
        this.handleError(error);
        return throwError('Something bad happened; please try again later.');
      });
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }
}
