import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'web-di-ld-apply-error',
  templateUrl: './apply-error.component.html',
  styleUrls: ['./apply-error.component.scss'],
})
export class ApplyErrorComponent {
  @Input() path = '';
  @Input() title = '';
  @Input() description = '';
  @Input() width = '';
  @Input() isShowLaterBtn = false;
  @Input() isPendingAnimate = false;
  @Input() btnText = 'รอผลภายหลัง';
  @Input() waitingDesc = '';
  @Input() isMarginTop = false;

  animateOptions: AnimationOptions = {
    path: '/assets/animation/pending-icon/pending-icon.json',
  };
  @Output() onClick = new EventEmitter();

  constructor() {}

  onLater() {
    this.onClick.emit();
  }
}
