import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'web-di-ld-apply-full-screen-error',
  templateUrl: './apply-full-screen-error.component.html',
  styleUrls: ['./apply-full-screen-error.component.scss'],
})
export class ApplyFullScreenErrorComponent {
  @Input() path = '';
  @Input() title = '';
  @Input() description = '';
  @Output() onClick = new EventEmitter();

  constructor() {}

  onSubmit() {
    this.onClick.emit();
  }
}
