<div class="container">
  <ng-container *transloco="let t">
    <ng-container *ngIf="!isLoading">
      <div class="p-d-flex p-ai-center p-jc-center p-d-flex-col">
        <img
          src="assets/images/illus-DGL-AcceptLoan.png"
          alt=""
          width="240px"
        />
        <h3 class="p-m-0" id="divApplicationStatusValue">
          {{ selectedData?.application?.statusDesc }}
        </h3>
        <p
          class="information-message p-mt-2 p-mb-0 p-text-center"
          id="divApplicationStatusNotification"
          *ngIf="selectedData?.application?.statusRemark"
        >
          {{ selectedData?.application?.statusRemark }}
        </p>
      </div>
      <div class="info-received-card p-p-3 p-d-flex p-mt-3 p-justify-between">
        <div>
          <div class="info-label">รับเงินสินเชื่อภายในวันที่</div>
          <div class="info-heading non-padding">
            {{
              selectedData?.application?.applicationExpiryDate
                | customThDate: 'd MMM yy':getCurrentLang
            }}
            {{
              selectedData?.application?.applicationExpiryDate | date: 'HH:mm'
            }}
          </div>
        </div>
        <div
          *ngIf="
            selectedData?.application?.followupExpireCountdown ||
            selectedData?.application?.applicationExpiryCountdown
          "
          class="p-d-flex"
        >
          <div
            class="arrive-day p-d-flex p-align-center p-px-2 p-py-1 align-end"
          >
            <img src="assets/images/ic_pending.svg" alt="" width="16px" />
            <div class="p-pl-2">
              {{ selectedData?.application?.applicationExpiryCountdown }}
            </div>
          </div>
        </div>
      </div>
      <div class="app-details p-mt-4">
        <div class="p-grid p-grid-card">
          <div
            class="p-col-4 info-label first-col"
            id="divApplicationStatusProductNameText"
          >
            {{ t('appStatus.product') }}
          </div>
          <div
            class="p-col-8 info-value first-col"
            id="divApplicationStatusProductNameValue"
          >
            {{ selectedData?.application?.productName }}
          </div>
          <div
            class="p-col-4 info-label p-pb-0"
            id="divApplicationStatusFullNameText"
          >
            {{ t('appStatus.fullName') }}
          </div>
          <div
            class="p-col-8 info-value p-pb-0"
            id="divApplicationStatusFullNameValue"
          >
            <span *ngIf="getCurrentLang === 'th'">{{
              selectedData?.customer?.fullNameTh
            }}</span>
            <span *ngIf="getCurrentLang === 'en'">{{
              selectedData?.customer?.fullNameTh
            }}</span>
          </div>
        </div>
        <div class="info-section-line p-my-3"></div>
        <!-- APPROVED LAYOUT -->
        <div class="p-grid p-grid-card">
          <div
            class="p-col-6 info-label first-col"
            id="divApplicationStatusLoanAmountText"
          >
            {{ t('confirmingDetails.loanAmount') }}
          </div>
          <div
            class="p-col-6 info-value first-col"
            id="divApplicationStatusLoanAmountValue"
          >
            <span class="loan-amount">{{
              parseToDecimal(selectedData?.application?.actualCreditLimit)
            }}</span>
            <span *ngIf="getCurrentLang === 'th'"> บาท </span>
            <span *ngIf="getCurrentLang === 'en'"> THB </span>
          </div>
          <div
            class="p-col-6 info-label"
            id="divApplicationStatusInterestRateText"
          >
            {{ t('appStatus.interestRate') }}
          </div>
          <div
            class="p-col-6 info-value loan-interest"
            id="divApplicationStatusInterestRateValue"
          >
            {{ selectedData?.application?.offerMaxInterestRate }}%
            <span *ngIf="getCurrentLang === 'th'"> ต่อปี </span>
            <span *ngIf="getCurrentLang === 'en'"> per year </span>
          </div>

          <div
            class="p-col-6 info-label"
            id="divApplicationStatusInstallementText"
          >
            {{ t('confirmingDetails.MonthlyInstallment') }}
          </div>
          <div
            class="p-col-6 info-value"
            id="divApplicationStatusInstallementValue"
          >
            <span class="loan-amount">{{
              parseToDecimal(selectedData?.application?.finalMonthlyInstallment)
            }}</span>
            <span *ngIf="getCurrentLang === 'th'"> บาท </span>
            <span *ngIf="getCurrentLang === 'en'"> THB </span>
          </div>

          <div
            class="p-col-6 info-label p-pb-0"
            id="divApplicationStatusPaymentTermText"
          >
            {{ t('confirmingDetails.paymentTerms') }}
          </div>
          <div
            class="p-col-6 info-value p-pb-0"
            id="divApplicationStatusPaymentTermValue"
          >
            {{ selectedData?.application?.actualPaymentTerm }}
            <span
              *ngIf="selectedData?.application?.selectedPaymentTermType === 'M'"
            >
              <span *ngIf="getCurrentLang === 'th'">
                {{ paymentTermEnum.MONTHS_TH }}
              </span>
              <span *ngIf="getCurrentLang === 'en'">
                {{ paymentTermEnum.MONTHS_EN }}
              </span>
            </span>
          </div>
        </div>
        <div class="info-section-line p-my-3"></div>
        <div class="p-grid p-grid-card">
          <div
            class="p-col-6 info-label first-col"
            id="divApplicationStatusFeeText"
          >
            {{ t('confirmingDetails.fee') }}
          </div>
          <div
            class="p-col-6 info-value first-col"
            id="divApplicationStatusFeeValue"
          >
            {{ t('confirmingDetails.percent') }}<br />
            {{ t('confirmingDetails.feeDescription') }}
          </div>
        </div>
        <div class="p-grid p-grid-card layout-ref">
          <div class="p-col-12 p-d-flex p-jc-between p-ai-center">
            <div class="info-label p-pb-0" id="divApplicationStatusRefNoText">
              {{ t('appStatus.refNo') }}
            </div>
            <div class="info-value p-pb-0" id="divApplicationStatusRefNoValue">
              {{ selectedData?.application?.applicationRef }}
            </div>
          </div>
        </div>

        <!-- ACCEPT OR REJECT BUTTON -->
        <div class="p-mt-4">
          <web-di-ld-button
            #nextButton
            id="divAcceptApplication"
            refId="BtnNcbConsent"
            label="{{ t('appStatus.acceptOffer') }}"
            [type]="'button'"
            (clickEvt)="onAccept()"
          ></web-di-ld-button>
          <div class="info-edit p-mt-4" id="divRejectApplication">
            <span (click)="onCancel()">{{ t('appStatus.rejectOffer') }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *transloco="let t">
    <ng-container *ngIf="isLoading">
      <web-di-ld-apply-error
        path="assets/images/DGL-Pending.png"
        title="{{ t('appStatus.approved.title') }}"
        description="{{ t('appStatus.approved.description') }}"
        [waitingDesc]="t('appStatus.approved.waitingDesc')"
        class="loading-application"
        btnText="{{ t('appStatus.approved.later') }}"
        [isMarginTop]="true"
        (onClick)="back()"
        [isShowLaterBtn]="isShowLaterBtn"
        [isPendingAnimate]="true"
      ></web-di-ld-apply-error>
    </ng-container>
  </ng-container>
</div>

<ng-container *transloco="let t">
  <ng-container
    *ngIf="dialogService.displayError"
    [ngSwitch]="dialogService.errorCode"
  >
    <web-di-ld-error
      id="divApplicationMoreThanSixtyCase"
      *ngSwitchCase="'moreThanSixty'"
      case="moreThanSixty"
      (clickEvt)="close()"
      img="reject"
      title="{{ t('applyError.titleHeader.registerStatus') }}"
    ></web-di-ld-error>
    <web-di-ld-error
      id="divApplicationNotPassSixCheck"
      *ngSwitchCase="'notPassSixCheck'"
      case="fullScreen"
      (clickEvt)="close()"
      img="reject"
      title="{{ t('applyError.titleHeader.registerStatus') }}"
    ></web-di-ld-error>
    <web-di-ld-error
      id="divApplicationExpired"
      *ngSwitchCase="'applicationExpired'"
      case="applicationExpired"
      (clickEvt)="close()"
      img="sadBird"
      title="{{ t('applyError.titleHeader.registerStatus') }}"
    ></web-di-ld-error>
  </ng-container>
</ng-container>
