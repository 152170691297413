<p-card
  (click)="onNavigateToAppInprogress()"
  id="cdrProductShelfAppInprogressMenu"
  class="top-card"
>
  <div class="p-d-flex p-ai-center p-grid">
    <div class="p-d-flex p-ai-center p-col-2 p-py-0">
      <img
        src="assets/images/app-status-v2.svg"
        alt=""
        width="40px"
        class="card-icon"
      />
    </div>
    <div class="p-col-9 p-py-0">
      <div class="title" id="divProductShelfAppInprogressMenuTitle">
        {{ 'loanProductShelf.appInprogressMenu.title' | transloco }}
      </div>
      <div class="description" id="divProductShelfAppInprogressMenuDescription">
        {{ 'loanProductShelf.appInprogressMenu.description' | transloco }}
      </div>
    </div>
    <div class="p-d-flex p-ai-center p-col-1">
      <!-- <img
        src="/assets/images/icon_arrow_right_default.png"
        class="arrow-right"
      /> -->
      <i class="pi pi-chevron-right arrow-right"></i>
    </div>
  </div>
</p-card>
