<!-- TOAST COMPONENT -->
<web-di-ld-toast></web-di-ld-toast>
<div *ngIf="isLoading === false" class="container-bg bg-color">
  <ng-container *transloco="let t">
    <div class="check-app" *ngIf="isShowCheckAppCard">
      <web-di-ld-check-app
        (onClick)="onNavigateToAppInprogress()"
      ></web-di-ld-check-app>
    </div>

    <div class="calculation">
      <web-di-ld-calculation-menu
        (onClick)="onNavigateToCalculation()"
      ></web-di-ld-calculation-menu>
    </div>
    <!-- <div *ngIf="topPromotionBanners && topPromotionBanners.length > 0">
      <web-di-ld-banner
        [autoSwipeTime]="autoSwipeTime"
        [items]="topPromotionBanners"
        (onClick)="onClickPromotionBanner($event)"
      >
      </web-di-ld-banner>
    </div>
    <div *ngIf="topSmartPromo && topSmartPromo.length > 0">
      <web-di-ld-promo
        [autoSwipeTime]="autoSwipeTimePromo"
        [items]="topSmartPromo"
        (onClick)="onClickSmartPromo($event)"
      ></web-di-ld-promo>
    </div>
    <div *ngIf="topupProductList && topupProductList.length > 0">
      <div class="loan-title p-ml-3" id="divProductShelfTitle">
        {{ 'topup.title' | transloco }}
      </div>
      <web-di-ld-products-topup
        [items]="topupProductList"
        (onClick)="onNavigateToTopupProduct($event)"
      ></web-di-ld-products-topup>
    </div> -->
    <div *ngIf="loanProductList && loanProductList.length > 0">
      <div class="loan-title" id="divProductShelfTitle">
        {{ 'loanProductShelf.title' | transloco }}
      </div>
      <div class="p-grid">
        <ng-container *ngFor="let product of loanProductList">
          <div class="p-col-12" *ngIf="product?.isEligible">
            <web-di-ld-card-product-shelf
              [productDetail]="product"
              (onSelected)="onNavigateToProduct($event, true)"
            >
            </web-di-ld-card-product-shelf>
          </div>
        </ng-container>
      </div>
      <div class="information">
        <web-di-ld-contact-information
          (onCall)="onCall()"
        ></web-di-ld-contact-information>
      </div>
    </div>
    <div
      *ngIf="
        !(uiLoadingService.isLoading$ | async) &&
        loanProductList?.length === 0 &&
        topupProductList?.length === 0
      "
      id="divProductShelfImageEmptyLoan"
    >
      <ng-container
        *ngIf="
          loanProductService.errorCode ===
            BE_ERROR_CODE.NOT_ELIGIBLE_ANY_PRODUCTS;
          else maintenance
        "
      >
        <div class="product-not-eligible">
          <img
            src="/assets/images/Missing Accounts.svg"
            alt=""
            width="171"
            height="120"
          />
          <div class="msg-product-not-eligible">
            {{ t('loanProductShelf.productNotEligible') }}
          </div>
        </div>
      </ng-container>
      <ng-template #maintenance>
        <img src="/assets/images/Banner_maintenance.png" alt="" />
      </ng-template>
    </div>

    <!-- <div
      *ngIf="bottomPromotionBanners && bottomPromotionBanners.length > 0"
      id="divProductShelfPromotionBanner"
    >
      <web-di-ld-banner
        [autoSwipeTime]="autoSwipeTime"
        [items]="bottomPromotionBanners"
        (onClick)="onClickPromotionBanner($event)"
      ></web-di-ld-banner>
    </div>
    <div *ngIf="bottomSmartPromo && bottomSmartPromo.length > 0">
      <web-di-ld-promo
        [autoSwipeTime]="autoSwipeTimePromo"
        [items]="bottomSmartPromo"
        (onClick)="onClickSmartPromo($event)"
      ></web-di-ld-promo>
    </div> -->
  </ng-container>
</div>

<ng-container *transloco="let t">
  <ng-container
    *ngIf="dialogService.displayError"
    [ngSwitch]="dialogService.errorCode"
  >
    <web-di-ld-error
      id="divProductHighlightDisplayErrorServiceImprovement"
      *ngSwitchCase="'verifyEmail'"
      case="email_verify"
      (clickEvt)="gotoVerifyEmail()"
      img="noVerifyEmail"
      [title]="t('applyError.titleHeader.emailVerify')"
    ></web-di-ld-error>
  </ng-container>
</ng-container>

<ng-container *transloco="let t">
  <p-dialog
    [(visible)]="dialogErrorHandlerProductShelf.displayErrorProductShelf"
    [modal]="true"
    [showHeader]="false"
  >
    <div>
      <web-di-ld-apply-error
        title="{{
          t('applyError.' + dialogErrorHandlerProductShelf.errorCode + '.title')
        }}"
      ></web-di-ld-apply-error>
    </div>
    <div class="button-container">
      <web-di-ld-button (clickEvt)="closeDialog()" label="{{ t('common.ok') }}">
      </web-di-ld-button>
    </div>
  </p-dialog>
</ng-container>
