import { DecimalPipe } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pathUrl } from 'apps/di-ld/src/app/configs';
import { PAYMENT_TYPE_TERM } from 'apps/di-ld/src/app/core/constant/base.enum';
import dayjs from 'dayjs';
import {
  JsInterfaceService,
  ApplicationsService,
} from '../../../../../core/services';
import {
  FirebaseParam,
  EventGATagging,
} from 'apps/di-ld/src/app/core/interfaces/js-interface.interface';
import {
  GA_EVENT_ACTION,
  GA_EVENT_CATEGORY,
  GA_CUSTOM_DIMENSION,
} from 'apps/di-ld/src/app/core/constant/base.enum';
import { AppStateService } from 'apps/di-ld/src/app/app-state.service';

@Component({
  selector: 'web-di-ld-status-accept',
  templateUrl: './status-accept.component.html',
  styleUrls: ['./status-accept.component.scss'],
})
export class StatusAcceptComponent implements OnInit {
  @Input() selectedData!: any;
  @Input() getCurrentLang?: string;
  @Input() from?: string;
  applicationRef = '';

  public paymentTermEnum = PAYMENT_TYPE_TERM;
  productType!: string;

  readonly decimalPipe = new DecimalPipe(this._locale);

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private router: Router,
    private jsInterfaceService: JsInterfaceService,
    private applicationsService: ApplicationsService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.applicationRef = this.selectedData.application.applicationRef;
    this.applicationsService.setGaTaggingBasedOnStatus(
      GA_EVENT_CATEGORY.ACCEPT_CONTRACT_COMPLETED
    );
    const event = {
      action: GA_EVENT_ACTION.VIEW,
      category: GA_EVENT_CATEGORY.ACCEPT_CONTRACT_COMPLETED,
      label: '',
      value: '',
    };
    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
    ];
    this.gaTagging(event, dimension);
  }

  arriveDate(date?: string) {
    const dateArrive = dayjs(date);
    const dateNow = dayjs();
    const arriveDate = dateArrive.diff(dateNow, 'day');
    return arriveDate + 1;
  }

  onDone() {
    const event = {
      action: GA_EVENT_ACTION.CLICK,
      category: GA_EVENT_CATEGORY.ACCEPT_CONTRACT_COMPLETED,
      label: `${GA_EVENT_CATEGORY.ACCEPT_CONTRACT_COMPLETED}_Done`,
      value: '',
    };

    let loanEffectiveDate = new Date(
      this.selectedData.application.acceptedDate
    );
    loanEffectiveDate.setDate(loanEffectiveDate.getDate() + 1);
    const loanEffectiveDateFormat =
      loanEffectiveDate.getFullYear() +
      '-' +
      (loanEffectiveDate.getMonth() + 1) +
      '-' +
      loanEffectiveDate.getDate();

    const dimension = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.selectedData.application.productType,
      },
      {
        key: GA_CUSTOM_DIMENSION.PT_INFORMATION,
        value: `AcptDate:${this.selectedData.application.acceptedDate}, EffDate:${loanEffectiveDateFormat}, Status:${this.selectedData.application.status}, Reminder:${this.selectedData.application.applicationExpiryCountdownEn}`,
      },
    ];
    const trackAppsflyer = true;
    this.gaTagging(event, dimension, trackAppsflyer);
    console.log('🚀 ~ Done from:', this.from);
    if (this.from) {
      this.appStateService.setAfterRating(false);
      this.router.navigate([pathUrl.applyRating], {
        state: {
          applicationRef: this.applicationRef,
          from: this.from,
        },
      });
    } else {
      this.router.navigate([pathUrl.loanProductShelf]);
    }
  }

  parseToDecimal(value: any) {
    return this.decimalPipe.transform(value, '1.2-2');
  }

  gaTagging(
    event: EventGATagging,
    dimension?: FirebaseParam[],
    trackAppsflyer?: boolean
  ) {
    this.jsInterfaceService.gaTagging(event, dimension, trackAppsflyer);
  }
}
