import { LOAN_PRODUCT_TYPE } from '../constant/base.enum';
export interface IDraft {
  loanInfo?: ILoanInfo | ITopupLoanInfo;
  businessInfo?: IBusinessInfo;
  additionalInfo?: IAdditionalInfo;
  marketingConsent?: boolean;
  peaConsent?: boolean;
  ncbConsent?: boolean;
  ocpbConsent?: boolean;
  mailingAddress?: IMailingAddress;
}
export interface IAdditionalInfo {
  educationCode?: string;
  educationLevelUpdFlag?: boolean;
  residence?: IResidence;
  maritalStatus?: string;
  maritalStatusUpdFlag?: boolean;
  children?: IChildren;
  twoMonthExistingLoan?: boolean;
  occupationGroupCode: string;
  subOccupationCode?: string;
  subOccupationFreeText?: string;
  yearsOfWorkingPeriod?: number;
  monthsOfWorkingPeriod?: number;
}

export interface ICdd {
  pepThailand?: boolean;
  pepThailandUpdFlag?: boolean;
  pepOversea?: boolean;
  pepOverseaUpdFlag?: boolean;
}

export interface IChildren {
  haveChildren?: boolean;
  numberOfChildren?: number; //int
}

export interface IResidence {
  currentResidenceCode?: string;
  periodYear?: number; //int
  periodMonth?: number; //int
}

export interface IBusinessInfo {
  businessName?: string;
  businessNameUpdFlag?: boolean;
  businessAddress?: IBusinessAddress;
}

export interface IBusinessAddress {
  address1?: string;
  address1UpdFlag?: boolean;
  address2?: string;
  address2UpdFlag?: boolean;
  address3?: string;
  address3UpdFlag?: boolean;
  address4?: string;
  address4UpdFlag?: boolean;
  provinceName?: string;
  provinceCode?: string;
  provinceUpdFlag?: boolean;
  districtName?: string;
  districtCode?: string;
  districtUpdFlag?: boolean;
  subdistrictName?: string;
  subdistrictCode?: string;
  subdistrictUpdFlag?: boolean;
  postCode?: string;
  postCodeUpdFlag?: boolean;
}

export interface ILoanInfo {
  referenceKey?: string;
  productCode?: LOAN_PRODUCT_TYPE;
  occupationSector?: string;
  monthlyIncome?: number; //float
  loanAmount?: number; //float
  selectedPaymentTerm?: number; //int
  confirmation?: boolean;
  uploadDocumentFlag?: boolean;
  [key: string]: any;
  referralCode?: string;
}

export interface ITopupLoanInfo extends ILoanInfo {
  currentDate?: string;
  isCheckAcknowledgement?: boolean;
}

export interface IMailingAddress extends IBusinessAddress {
  country: string;
  countryUpdFlag: boolean;
}
