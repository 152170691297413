<ng-container *transloco="let t">
  <div class="error-container" *ngIf="dialogService.displayError">
    <div class="p-d-flex title-wrapper">
      <span
        class="arrow-back"
        *ngIf="enableBackButton"
        (click)="emitClickOnBack()"
      >
        <i class="pi pi-chevron-left"></i>
      </span>
      <div class="title" *ngIf="title">{{ title }}</div>
    </div>
    <web-di-ld-apply-error
      *ngIf="path"
      [path]="path"
      [width]="width"
      title="{{
        t('applyError.' + case + '.title', {
          message: moreTitle,
          maxSubmission: maxSubmission,
          cutOffDay: cutOffDay
        })
      }}"
      description="{{
        t('applyError.' + case + '.description', {
          message: moreMessage,
          maxSubmission: maxSubmission,
          cutOffDay: cutOffDay
        })
      }}"
    ></web-di-ld-apply-error>
    <!-- {{ dialogService.errorCode }} -->
    <web-di-ld-button
      [label]="buttonTitle ? buttonTitle : t(buttonText())"
      (click)="emitClickEvt()"
    ></web-di-ld-button>
  </div>
</ng-container>
