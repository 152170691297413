import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DialogErrorHandlerService {
  // For Specific Dialog Error
  public displayErrorProductShelf: boolean = false;
  public errorTitle: string = '';
  public errorCode: string = '';
  public errorMessage: string = '';

  constructor() {}

  setError(title: string = '', errorCode: string, message: string = '') {
    if (this.displayErrorProductShelf === false) {
      // if no dialog then show fullscreen error
      this.errorTitle = title;
      this.displayErrorProductShelf = true;
      this.errorCode = errorCode;
      this.errorMessage = message;
    }
  }
}
