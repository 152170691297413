import { DecimalPipe } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import {
  GA_EVENT_CATEGORY,
  PAYMENT_TYPE_TERM,
} from 'apps/di-ld/src/app/core/constant/base.enum';
import dayjs from 'dayjs';
import {
  FirebaseParam,
  EventGATagging,
} from 'apps/di-ld/src/app/core/interfaces/js-interface.interface';
import {
  ApplicationsService,
  JsInterfaceService,
} from 'apps/di-ld/src/app/core/services';

@Component({
  selector: 'web-di-ld-status-process',
  templateUrl: './status-process.component.html',
  styleUrls: ['./status-process.component.scss'],
})
export class StatusProcessComponent implements OnInit {
  @Input() selectedData!: any;
  @Input() getCurrentLang?: string;

  public paymentTermEnum = PAYMENT_TYPE_TERM;
  readonly decimalPipe = new DecimalPipe(this._locale);

  constructor(
    @Inject(LOCALE_ID) public _locale: string,
    private jsInterfaceService: JsInterfaceService,
    private applicationsService: ApplicationsService
  ) {}

  ngOnInit(): void {
    this.applicationsService.setGaTaggingBasedOnStatus(
      GA_EVENT_CATEGORY.PENDING_APPROVE
    );
  }

  arriveDate(date?: string) {
    const dateArrive = dayjs(date);
    const dateNow = dayjs();
    const arriveDate = dateArrive.diff(dateNow, 'day');
    return arriveDate + 1;
  }

  parseToDecimal(value: any) {
    return this.decimalPipe.transform(value, '1.2-2');
  }

  gaTagging(event: EventGATagging, dimension?: FirebaseParam[]) {
    this.jsInterfaceService.gaTagging(event, dimension);
  }
}
