import { Injectable } from '@angular/core';
import { HttpService } from '@web-di-ld/apps/http';
import { AppStateService } from '../../../app-state.service';
import {
  ILoanOffers,
  IProductDetail,
  IProductShelf,
  IGetActiveList,
} from '../../interfaces';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoanProductService {
  public errorCode: string = '';
  public loanProduct: any;

  constructor(
    private appState: AppStateService,
    private httpService: HttpService
  ) {}

  setLoanProduct(loanProduct: any) {
    this.loanProduct = loanProduct;
  }

  getLoans() {
    return this.httpService
      .get<IProductShelf>('/lfm-offers/app/v1/offers')
      .toPromise()
      .then((response: any) => {
        this.errorCode = '';
        return response;
      })
      .catch((error) => {
        this.errorCode = error.error.code;
        console.error(error);
        return [];
      });
  }

  getPaotangLoans() {
    return this.httpService
      .get<IProductShelf>(`/lfm-offers/app/v1/paotang/offers`)
      .toPromise()
      .then((response: any) => {
        this.errorCode = '';
        return response;
      })
      .catch((error) => {
        this.errorCode = error.error.code;
        console.error(error);
        return [];
      });
  }

  public updateLoanOffers(loanOffers: ILoanOffers) {
    this.appState.updateStateLoanOffers(loanOffers);
  }

  public updateLoanProduct(loanProduct: IProductShelf) {
    this.appState.updateStateLoanProduct(loanProduct);
  }

  public clearProductDetail() {
    const emptyProductDetail = ({} as any) as IProductDetail;
    this.appState.updateStateProductDetail(emptyProductDetail);
  }

  async getActiveList(): Promise<IGetActiveList> {
    return this.httpService
      .get<IGetActiveList>(
        `/lfm-applications/app/v2/application/get-active-list`
      )
      .toPromise()
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return EMPTY;
      });
  }
}
