import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './core/template/main/main.component';
import { pathUrl } from './configs';
import { AisConsentComponent } from './pages/ais-consent/ais-consent.component';
import { LoanApplyComponent } from './pages/loan-apply/loan-apply.component';

const removeSlash = (path: string) => path.replace('/', '');

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: removeSlash(pathUrl.aisKtbTermAndCon),
        loadChildren: () =>
          import(
            './pages/ais-ktb-term-and-con/ais-ktb-term-and-con.module'
          ).then((m) => m.AisKtbTermAndConModule),
      },
      {
        path: removeSlash(pathUrl.aisOtp),
        loadChildren: () =>
          import('./pages/ais-otp/ais-otp.module').then((m) => m.AisOtpModule),
      },
      {
        path: removeSlash(pathUrl.aisConsent),
        loadChildren: () =>
          import('./pages/ais-consent/ais-consent.module').then(
            (m) => m.AisConsentModule
          ),
      },
      {
        path: removeSlash(pathUrl.loanProductShelf),
        loadChildren: () =>
          import('./pages/loan-product-shelf/loan-product-shelf.module').then(
            (m) => m.LoanProductShelfModule
          ),
      },
      {
        path: removeSlash(pathUrl.ncbConsent),
        loadChildren: () =>
          import('./pages/ncb-consent/ncb-consent.module').then(
            (m) => m.NcbConsentModule
          ),
      },
      {
        path: removeSlash(pathUrl.marketingConsent),
        loadChildren: () =>
          import('./pages/marketing-consent/marketing-consent.module').then(
            (m) => m.MarketingConsentModule
          ),
      },
      {
        path: removeSlash(pathUrl.peaConsent),
        loadChildren: () =>
          import('./pages/pea-consent/pea-consent.module').then(
            (m) => m.PeaConsentModule
          ),
      },
      {
        path: removeSlash(pathUrl.confirmingDetails),
        loadChildren: () =>
          import('./pages/confirming-details/confirming-details.module').then(
            (m) => m.ConfirmingDetailsModule
          ),
      },
      {
        path: removeSlash(pathUrl.editInformation),
        loadChildren: () =>
          import(
            './pages/personal/edit-information/edit-information.module'
          ).then((m) => m.EditInformationModule),
      },
      {
        path: removeSlash(pathUrl.editMailingAddress),
        loadChildren: () =>
          import(
            './pages/personal/edit-information/edit-information.module'
          ).then((m) => m.EditInformationModule),
      },
      // {
      //   path: 'additional-information',
      //   loadChildren: () =>
      //     import(
      //       './pages/personal/additional-information/additional-information.module'
      //     ).then((m) => m.AdditionalInformationModule),
      // },
      // {
      //   path: 'personal-information',
      //   loadChildren: () =>
      //     import(
      //       './pages/personal/personal-information/personal-information.module'
      //     ).then((m) => m.PersonalInformationModule),
      // },
      {
        path: removeSlash(pathUrl.loanApply.apply),
        loadChildren: () =>
          import('./pages/loan-apply/loan-apply.module').then(
            (m) => m.LoanApplyModule
          ),
      },
      {
        path: 'personal',
        loadChildren: () =>
          import('./pages/personal/personal-step/personal-step.module').then(
            (m) => m.PersonalStepModule
          ),
      },
      {
        path: 'rating',
        loadChildren: () =>
          import('./pages/confirm-summary/confirm-summary.module').then(
            (m) => m.ConfirmSummaryModule
          ),
      },
      {
        path: removeSlash(pathUrl.loanApply.topup),
        loadChildren: () =>
          import('./pages/topup/topup.module').then((m) => m.TopupModule),
      },
      {
        path: removeSlash(pathUrl.paymentDetails),
        loadChildren: () =>
          import(
            './pages/payment-details/payment-info/payment-info.module'
          ).then((m) => m.PaymentInfoModule),
      },
      {
        path: removeSlash(pathUrl.editAddress),
        loadChildren: () =>
          import(
            './pages/payment-details/edit-address/edit-address.module'
          ).then((m) => m.EditAddressModule),
      },
      {
        path: removeSlash(pathUrl.applyConfirmationInfo),
        loadChildren: () =>
          import(
            './pages/apply-confirmation-info/apply-confirmation-info.module'
          ).then((m) => m.ApplyConfirmationInfoModule),
      },
      {
        path: removeSlash(pathUrl.applyProductDetail),
        loadChildren: () =>
          import(
            './pages/apply-product-detail/apply-product-detail.module'
          ).then((m) => m.ApplyProductDetailModule),
      },
      {
        path: removeSlash(pathUrl.applyStatusPendingApprove),
        loadChildren: () =>
          import(
            './pages/apply-status-pending-approve/apply-status-pending-approve.module'
          ).then((m) => m.ApplyStatusPendingApproveModule),
      },
      {
        path: removeSlash(pathUrl.applyRating),
        loadChildren: () =>
          import('./pages/apply-rating/apply-rating.module').then(
            (m) => m.ApplyRatingModule
          ),
      },
    ],
  },
  {
    path: removeSlash(pathUrl.promotion),
    loadChildren: () =>
      import('./pages/promotion/promotion.module').then(
        (m) => m.PromotionModule
      ),
  },
  {
    path: removeSlash(pathUrl.appInprogress),
    loadChildren: () =>
      import('./pages/app-inprogress/app-inprogress.module').then(
        (m) => m.AppInprogressModule
      ),
  },
  {
    path: removeSlash(pathUrl.appDetails),
    loadChildren: () =>
      import('./pages/app-details/app-details.module').then(
        (m) => m.AppDetailsModule
      ),
  },
  {
    path: removeSlash(pathUrl.applySuccess),
    loadChildren: () =>
      import('./pages/apply-success/apply-success.module').then(
        (m) => m.ApplySuccessModule
      ),
  },
  {
    path: removeSlash(pathUrl.turndown),
    loadChildren: () =>
      import('./pages/turndown/turndown.module').then((m) => m.TurndownModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
