import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaxLengthDirective } from './max-length.directive';
import { CustomScrollDirective } from './custom-scroll/custom-scroll.directive';

@NgModule({
  declarations: [MaxLengthDirective, CustomScrollDirective],
  imports: [CommonModule],
  exports: [MaxLengthDirective, CustomScrollDirective],
})
export class DirectiveModule {}
