<div class="header">
  <web-di-ld-header
    title="{{ titleHeader() | transloco }}"
    [path]="getPath()"
    share="{{ share }}"
  ></web-di-ld-header>
</div>

<!-- <div [ngClass]="{ container: titleHeader$ | async }"> -->
<ng-container *transloco="let t">
  <p-dialog
    [(visible)]="noConnectionDialog"
    [modal]="true"
    [showHeader]="false"
  >
    <div>
      <web-di-ld-apply-error
        title="{{ t('applyError.no_internet_connection.title') }}"
        description="{{ t('applyError.no_internet_connection.description') }}"
      ></web-di-ld-apply-error>
    </div>
    <div class="button-container">
      <web-di-ld-button (clickEvt)="closeDialog()" label="{{ t('common.ok') }}">
      </web-di-ld-button>
    </div>
  </p-dialog>
</ng-container>
<div>
  <router-outlet></router-outlet>
</div>
<web-di-ld-ui-loading
  *ngIf="uiLoadingService.isLoading$ | async"
></web-di-ld-ui-loading>

<web-di-ld-dialog-error></web-di-ld-dialog-error>
