<div class="container">
  <ng-container *transloco="let t">
    <div class="p-d-flex p-ai-center p-jc-center p-d-flex-col">
      <img src="assets/images/app-detail-pending.png" alt="" width="210px" />
      <h3 class="p-mb-0 p-mt-3" id="divApplicationStatusValue">
        {{ selectedData?.application?.statusDesc }}
      </h3>
      <p
        class="information-message p-mt-1 p-text-center"
        id="divApplicationStatusNotification"
      >
        {{ selectedData?.application?.statusRemark }}
      </p>
    </div>
    <div class="app-details">
      <div class="info-section-line p-mb-3"></div>
      <div class="p-grid p-grid-card">
        <div
          class="p-col-4 info-label first-col"
          id="divApplicationStatusProductNameText"
        >
          {{ t('appStatus.product') }}
        </div>
        <div
          class="p-col-8 info-value first-col"
          id="divApplicationStatusProductNameValue"
        >
          {{ selectedData?.application?.productName }}
        </div>
        <div
          class="p-col-4 info-label p-pb-0"
          id="divApplicationStatusFullNameText"
        >
          {{ t('appStatus.fullName') }}
        </div>
        <div
          class="p-col-8 info-value p-pb-0"
          id="divApplicationStatusFullNameValue"
        >
          <span *ngIf="getCurrentLang === 'th'">{{
            selectedData?.customer?.fullNameTh
          }}</span>
          <span *ngIf="getCurrentLang === 'en'">{{
            selectedData?.customer?.fullNameTh
          }}</span>
        </div>
      </div>
      <div class="info-section-line p-my-3"></div>
      <!-- OTHER LAYOUT -->

      <div class="p-grid p-grid-card">
        <div
          class="p-col-6 info-label first-col"
          id="divApplicationStatusLoanAmountText"
        >
          {{ t('appStatus.loanAmount') }}
        </div>
        <div
          class="p-col-6 info-value first-col"
          id="divApplicationStatusLoanAmountValue"
        >
          <span class="loan-amount">{{
            parseToDecimal(selectedData?.application?.requestCreditLimit)
          }}</span>
          <span *ngIf="getCurrentLang === 'th'"> บาท </span>
          <span *ngIf="getCurrentLang === 'en'"> THB </span>
        </div>
        <div
          class="p-col-6 info-label"
          id="divApplicationStatusInterestRateText"
        >
          {{ t('appStatus.interestRate') }}
        </div>
        <div
          class="p-col-6 info-value loan-interest"
          id="divApplicationStatusInterestRateValue"
        >
          {{ selectedData?.application?.offerMaxInterestRate }}%
          <span *ngIf="getCurrentLang === 'th'"> ต่อปี </span>
          <span *ngIf="getCurrentLang === 'en'"> per year </span>
        </div>

        <div
          class="p-col-6 info-label"
          id="divApplicationStatusInstallementText"
        >
          {{ t('appStatus.estimatesMonthlyInstallment') }}
        </div>
        <div
          class="p-col-6 info-value"
          id="divApplicationStatusInstallementValue"
        >
          <span class="loan-amount">{{
            parseToDecimal(selectedData?.application?.maxInstallmentAmount)
          }}</span>
          <span *ngIf="getCurrentLang === 'th'"> บาท </span>
          <span *ngIf="getCurrentLang === 'en'"> THB </span>
        </div>

        <div
          class="p-col-6 info-label"
          id="divApplicationStatusPaymentTermText"
        >
          {{ t('appStatus.paymentTerms') }}
        </div>
        <div
          class="p-col-6 info-value"
          id="divApplicationStatusPaymentTermValue"
        >
          {{ selectedData?.application?.selectedPaymentTerm }}
          <span
            *ngIf="selectedData?.application?.selectedPaymentTermType === 'M'"
          >
            <span *ngIf="getCurrentLang === 'th'">
              {{ paymentTermEnum.MONTHS_TH }}
            </span>
            <span *ngIf="getCurrentLang === 'en'">
              {{ paymentTermEnum.MONTHS_EN }}
            </span>
          </span>
        </div>
        <div
          class="p-col-12 info-label p-pb-0 p-mb-3"
          id="divApplicationStatusDescriptionValue"
        >
          <div class="p-d-flex info-label-color">
            <div class="info-start">
              <i class="pi pi-info-circle"></i>
            </div>
            <div class="p-ml-2">
              {{ t('appStatus.installmentAmountPerMonth') }}
            </div>
          </div>
        </div>
      </div>
      <div class="info-section-line"></div>
      <div class="p-grid p-grid-card">
        <div
          class="p-col-6 info-label p-my-3 p-py-0"
          id="divApplicationStatusFeeText"
        >
          {{ t('appStatus.fee') }}
        </div>
        <div
          class="p-col-6 info-value p-my-3 p-py-0"
          id="divApplicationStatusFeeValue"
        >
          {{ t('appStatus.percent') }}<br />
          {{ t('appStatus.feeDescription') }}
        </div>
      </div>
      <div class="info-section-line"></div>
      <div class="p-grid p-grid-card">
        <div
          class="p-col-6 info-label p-pt-0 p-mt-3"
          id="divApplicationStatusDateText"
        >
          {{ t('appStatus.applicationDate') }}
        </div>
        <div
          class="p-col-6 info-value p-pt-0 p-mt-3"
          id="divApplicationStatusDateValue"
        >
          {{
            selectedData?.application?.submitDate
              | customThDate: 'd MMM yy':getCurrentLang
          }}
          {{ selectedData?.application?.submitDate | date: 'HH:mm' }}
        </div>
      </div>
      <div class="p-d-flex p-jc-between p-ai-center p-mb-3">
        <div class="info-label" id="divApplicationStatusRefNoText">
          {{ t('appStatus.refNo') }}
        </div>
        <div class="info-value" id="divApplicationStatusRefNoValue">
          {{ selectedData?.application?.applicationRef }}
        </div>
      </div>
      <div class="p-grid p-grid-card info-alert-card p-mt-2">
        <div
          class="p-col-12 info-label"
          id="divApplicationStatusDescriptionValue"
        >
          <div class="p-d-flex info-alert-text">
            <div class="info-start">
              <i class="pi pi-info-circle"></i>
            </div>
            <div class="p-ml-2">
              {{
                t('appStatus.notifyDialog', {
                  email: selectedData?.customer?.email
                })
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
