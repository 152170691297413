import { Pipe, PipeTransform } from '@angular/core';
import { SelectComponent } from './select.component';

@Pipe({
  name: 'acctNoFormat',
})
export class AccountNumberFormat implements PipeTransform {
  constructor(readonly selectComponent: SelectComponent) {}
  transform(value: string): string {
    return '*** * **' + value.slice(-4, -1) + ' ' + value.slice(-1);
  }
}
