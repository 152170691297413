import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { pathUrl } from 'apps/di-ld/src/app/configs';
import { JsInterfaceService } from 'apps/di-ld/src/app/core/services/shared/js-interface.service';
import { AppStateService } from 'apps/di-ld/src/app/app-state.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  EventGATagging,
  FirebaseParam,
} from 'apps/di-ld/src/app/core/interfaces/js-interface.interface';
import { FLOW_STATE, GaTaggingInfo } from 'apps/di-ld/src/app/core/interfaces';
import {
  ApplicationsService,
  DraftService,
} from 'apps/di-ld/src/app/core/services';
import { Subscription } from 'rxjs';
import {
  ACCEPT_CONTRACT_FLOW,
  CANCEL_FLOW,
  GA_CUSTOM_DIMENSION,
  GA_EVENT_ACTION,
  GA_EVENT_CATEGORY,
  PAGE_NAME,
} from 'apps/di-ld/src/app/core/constant/base.enum';
import { DialogErrorHandlerService } from '../../../error-handler/dialog-error-handler.service';
import { AisService } from 'apps/di-ld/src/app/core/services/ais/ais.service';

declare const window: any;

@Component({
  selector: 'web-di-ld-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title!: string;
  @Input() path!: string;
  @Input() url!: string;
  @Input() share!: string;
  navigator = window.navigator as any;
  subscription = new Subscription();
  public productType!: string;
  public PAGE_NAME = PAGE_NAME;
  public gaTaggingInfo!: GaTaggingInfo;
  prevUrl? = '';

  constructor(
    private router: Router,
    private location: Location,
    private jsInterfaceService: JsInterfaceService,
    private appState: AppStateService,
    private draftService: DraftService,
    public dialogService: DialogErrorHandlerService,
    private applicationsService: ApplicationsService,
    private aisService: AisService,
    private zone: NgZone
  ) {
    const _this = this;
    window.onMobileBackPressed = function () {
      zone.run(() => {
        console.log('onMobileBackPressed');
        // back to path before (status page)
        // if (appState.isCloseWebView) {
        //   console.log('isCloseWebView');
        //   jsInterfaceService.closeWebView();
        // } else {
        //   console.log('location.back()');
        // this.prevUrl ? router.navigate([this.prevUrl]) : location.back();
        // _this.navigateTo(_this.url);
        // }
      });
      return true;
    };
    window.addEventListener('popstate', function () {
      zone.run(() => {
        console.log('popstates');
        // this.navigateTo(this.url);
        const currentUrl = _this.router.url;
        const url = _this.router.url;
        // console.log('🚀 ~ _this.prevUrl:', _this.previousUrl(currentUrl));
        console.log('🚀 ~ currentUrl:', currentUrl);
        console.log('🚀 ~ currentUrl(router):', url);
        console.log('🚀 ~ _this.prevUrl:', _this.prevUrl);
        console.log('🚀 ~ _this.url:', _this.url);

        const {
          flow,
          enterFlow,
          state,
          isTopup,
        } = _this.appState.snapshot.flow;
        console.log(
          `🚥 ~ flow: ${flow}, enterFlow: ${enterFlow}, state: ${state}`
        );

        // INITIAL APP ON BACK ON DEVICE PREVENT REDIRECT
        if (!_this.appState.isInitial) {
          _this.appState.setInitial(true);
        }

        if (!_this.prevUrl && currentUrl === pathUrl.loanProductShelf) {
          console.log('call jsBridge close web view');
          _this.jsInterfaceService.closeWebView();
        } else if (
          flow === FLOW_STATE.SHELF &&
          currentUrl === pathUrl.loanProductShelf
        ) {
          console.log(
            `reached shelf when pressed back button; so back to home`
          );
          _this.jsInterfaceService.closeWebView();
        } else if (flow === FLOW_STATE.CALCULATE) {
          if (url == pathUrl.loanApply.apply) {
            setTimeout(() => {
              _this.router.navigateByUrl(pathUrl.loanApply.calculation, {
                replaceUrl: true,
              });
            }, 0);
          } else if (url === pathUrl.loanApply.calculation) {
            setTimeout(() => {
              _this.router.navigateByUrl(pathUrl.loanProductShelf, {
                replaceUrl: true,
              });
            }, 0);
          } else {
            setTimeout(() => _this.navigateTo(url));
          }
        } else if (
          currentUrl === pathUrl.aisKtbTermAndCon ||
          currentUrl === pathUrl.aisOtp ||
          currentUrl === pathUrl.aisConsent
        ) {
          setTimeout(() => _this.navigateTo(currentUrl));
          // _this.navigateTo();
        } else {
          console.log(`PASS else; back on device`);
          // setTimeout(() => _this.navigateTo(url));
        }
        // if (!appState.isInitial) {
        // _this.prevUrl ? router.navigate([_this.prevUrl]) : location.back();
        // }
        // OLD CODE 1-2 week ago
        // _this.navigateTo(_this.url);
        return false;
      });
      return true;
    });
  }

  ngOnInit(): void {
    const currentUrl = this.location.path();
    console.log('🚀 ~ HEADER > ngOnInit > currentUrl:', currentUrl);
    let returnPath = this.getPath(currentUrl);
    console.log('🚀 ~ HEADER > ngOnInit > returnPath:', returnPath);
    this.setProductType();
  }

  setProductType() {
    // SUBSCRIPTION PRODUCT DEFECT GA TAGGING
    this.subscription.add(
      this.appState.ProductDetail$.pipe(
        distinctUntilChanged(),
        debounceTime(400)
      ).subscribe((productConfig) => {
        // console.log('🚀 ~ productConfig:', productConfig);
        this.productType = productConfig.productCode;
      })
    );
  }

  previousUrl(path: string) {
    const { flow, enterFlow, state, isTopup } = this.appState.snapshot.flow;
    let returnPath = this.getPath(path, flow, enterFlow, isTopup);
    if (!!!returnPath) {
      this.jsInterfaceService.closeWebView();
      return;
    }

    if (returnPath === path) {
      return this.getPath(path);
    } else {
      return returnPath;
    }
  }

  getPath(
    path: string,
    flow?: FLOW_STATE,
    enterFlow?: FLOW_STATE,
    isTopup?: boolean
  ) {
    switch (path) {
      case pathUrl.loanApply.apply:
        return flow === FLOW_STATE.COMFIRM
          ? pathUrl.confirmingDetails
          : enterFlow === FLOW_STATE.CALCULATE
          ? pathUrl.loanApply.calculation
          : pathUrl.loanProductShelf;
      case pathUrl.loanApply.topupDetail:
        return flow === FLOW_STATE.TOPUP_CONFIRM
          ? pathUrl.loanApply.topupConfirm
          : enterFlow === FLOW_STATE.CALCULATE
          ? pathUrl.loanApply.calculation
          : pathUrl.loanProductShelf;
      case pathUrl.loanApply.productDetail:
        return flow === FLOW_STATE.TOPUP_DETAIL
          ? pathUrl.loanApply.topup
          : pathUrl.loanApply.apply;
      case pathUrl.loanApply.calculation:
        return pathUrl.loanProductShelf;
      case pathUrl.personalInformation:
        return flow === FLOW_STATE.COMFIRM
          ? pathUrl.confirmingDetails
          : pathUrl.loanApply.apply;
      case pathUrl.additionalInformation:
        return flow === FLOW_STATE.COMFIRM
          ? pathUrl.confirmingDetails
          : pathUrl.personalInformation;
      case pathUrl.editInformation:
        return isTopup
          ? pathUrl.loanApply.topupConfirm
          : pathUrl.personalInformation;
      case pathUrl.confirmingDetails:
        return flow === FLOW_STATE.COMFIRM
          ? pathUrl.confirmingDetails
          : pathUrl.additionalInformation;
      case pathUrl.marketingConsent:
        return isTopup
          ? pathUrl.loanApply.topupConfirm
          : pathUrl.confirmingDetails;
      case pathUrl.peaConsent:
        return pathUrl.marketingConsent;
      case pathUrl.ncbConsent:
        return pathUrl.marketingConsent;
      case pathUrl.aisKtbTermAndCon:
        if (flow === FLOW_STATE.CALCULATE) {
          return pathUrl.loanApply.calculation;
        } else {
          return pathUrl.loanProductShelf;
        }
      case pathUrl.aisOtp:
        return pathUrl.aisKtbTermAndCon;
      case pathUrl.aisConsent:
        return pathUrl.aisKtbTermAndCon;
      // return pathUrl.peaConsent;
      case pathUrl.confirmSummary:
        return pathUrl.confirmSummary;
      case pathUrl.promotion:
        return pathUrl.loanProductShelf;
      case pathUrl.loanApply.topupAdditional:
        return flow === FLOW_STATE.TOPUP_CONFIRM
          ? pathUrl.loanApply.topupConfirm
          : pathUrl.loanApply.topupDetail;
      case pathUrl.loanApply.topupConfirm:
        return pathUrl.loanApply.topupAdditional;
      case pathUrl.editMailingAddress:
        return isTopup ? pathUrl.loanApply.topup : pathUrl.personalInformation;
      case pathUrl.appInprogress:
        return pathUrl.loanProductShelf;
      case pathUrl.appDetails:
        return pathUrl.appInprogress;
      case pathUrl.paymentDetails:
        return pathUrl.appDetails;
      case pathUrl.editAddress:
        return pathUrl.paymentDetails;
      case pathUrl.applyConfirmationInfo:
        return pathUrl.paymentDetails;
      case pathUrl.applyProductDetail:
        return pathUrl.applyConfirmationInfo;
      case pathUrl.applyStatusPendingApprove:
        return pathUrl.applyProductDetail;
      // case pathUrl.applyRating:
      //   return pathUrl.applyStatusPendingApprove;
      case pathUrl.turndown:
        return pathUrl.appDetails;
      default:
        return '';
    }
  }

  getPageEvent(): GA_EVENT_CATEGORY | string {
    const currentUrl = this.location.path();
    switch (currentUrl) {
      case pathUrl.loanApply.apply:
        return GA_EVENT_CATEGORY.LOAN_APPLY;
      case pathUrl.loanApply.productDetail:
        return GA_EVENT_CATEGORY.PRODUCT_DETAIL;
      case pathUrl.loanApply.calculation:
        return GA_EVENT_CATEGORY.LOAN_CALCULATE;
      case pathUrl.personalInformation:
        return GA_EVENT_CATEGORY.PERSONAL_INFORMATION;
      case pathUrl.additionalInformation:
        return GA_EVENT_CATEGORY.ADDITIONAL_INFORMATION;
      case pathUrl.editInformation:
        return GA_EVENT_CATEGORY.EDIT_INFORMATION;
      case pathUrl.confirmingDetails:
        return GA_EVENT_CATEGORY.CONFIRM_DETAIL;
      case pathUrl.marketingConsent:
        return GA_EVENT_CATEGORY.MARKETING_CONSENT;
      case pathUrl.ncbConsent:
        return GA_EVENT_CATEGORY.NCB_CONSENT;
      case pathUrl.promotion:
        return GA_EVENT_CATEGORY.PROMOTION;
      case pathUrl.loanApply.topupDetail:
        return GA_EVENT_CATEGORY.TOPUP_LOAN_DETAIL;
      case pathUrl.loanApply.topupAdditional:
        return GA_EVENT_CATEGORY.TOPUP_ADDITIONAL_DETAIL;
      case pathUrl.loanApply.topupConfirm:
        return GA_EVENT_CATEGORY.TOPUP_CONFIRMATION;
      case pathUrl.editMailingAddress:
        return GA_EVENT_CATEGORY.EDIT_MAILING_ADDRESS;
      case pathUrl.appDetails:
        return this.applicationsService.getGaTaggingBasedOnStatus();
      case pathUrl.paymentDetails:
        return GA_EVENT_CATEGORY.PAYMENT_DETAILS;
      case pathUrl.editAddress:
        return GA_EVENT_CATEGORY.UPDATE_MAILING;
      case pathUrl.applyConfirmationInfo:
        return GA_EVENT_CATEGORY.CONFIRM_INFO;
      case pathUrl.applyProductDetail:
        return GA_EVENT_CATEGORY.ACCEPT_CONTRACT;
      case pathUrl.turndown:
        return GA_EVENT_CATEGORY.TURNDOWN;
      case pathUrl.appInprogress:
        return GA_EVENT_CATEGORY.APP_STATUS_LIST;
      case pathUrl.aisKtbTermAndCon:
        return GA_EVENT_CATEGORY.AIS_T_AND_C;
      case pathUrl.aisOtp:
        return GA_EVENT_CATEGORY.AIS_OTP;
      case pathUrl.aisConsent:
        return GA_EVENT_CATEGORY.AIS_CONSENT;
      default:
        return '';
    }
  }

  gaTaggingConditionForAppDetail(currentUrl: string) {
    return (
      currentUrl === pathUrl.appDetails &&
      (this.applicationsService.getGaTaggingBasedOnStatus() ===
        GA_EVENT_CATEGORY.ACCEPT_APPROVED ||
        this.applicationsService.getGaTaggingBasedOnStatus() ===
          GA_EVENT_CATEGORY.FOLLOWUP_REVIEW_DOC_UPLOAD)
    );
  }

  // gaTaggingConditionForUnsuccess(currentUrl: string) {
  //   return (
  //     currentUrl === pathUrl.appDetails &&
  //     this.applicationsService.getGaTaggingBasedOnStatus() ===
  //       GA_EVENT_CATEGORY.STATUS_UNSUCCESS
  //   );
  // }

  private getProductType(): string {
    const currentUrl = this.location.path();
    switch (currentUrl) {
      case pathUrl.aisKtbTermAndCon:
      case pathUrl.aisOtp:
      case pathUrl.aisConsent:
        return this.appState.snapshot.loanProduct.productCode;
      default:
        return this.productType;
    }
  }

  private getAdditionalDimensions(): FirebaseParam[] {
    const currentUrl = this.location.path();
    switch (currentUrl) {
      case pathUrl.aisOtp:
        return [this.aisService.informationDimension];
      default:
        return [];
    }
  }

  navigateTo(url: string = '') {
    const currentUrl = !!url ? url : this.location.path();
    const pageEvent = this.getPageEvent();
    console.log('🚀 ~ pageEvent:', pageEvent);
    if (!this.productType || !this.gaTaggingInfo) {
      this.gaTaggingInfo = this.applicationsService.getGaTaggingInfo();
      console.log('🚀 ~ this.gaTaggingInfo:', this.gaTaggingInfo);
      this.productType = this.gaTaggingInfo?.productType;
      console.log('🚀 ~ this.productType:', this.productType);
    }
    const additionalDimensions = this.getAdditionalDimensions();
    const event: EventGATagging = {
      action: GA_EVENT_ACTION.CLICK,
      category: pageEvent,
      label: `${pageEvent}_Back`,
      value: '',
    };
    const dimension: FirebaseParam[] = [
      {
        key: GA_CUSTOM_DIMENSION.KEY,
        value: this.getProductType(),
      },
      ...additionalDimensions,
    ];

    if (
      this.gaTaggingConditionForAppDetail(currentUrl) ||
      currentUrl === pathUrl.applyProductDetail ||
      currentUrl === pathUrl.turndown
    ) {
      const ptInformation = {
        key: GA_CUSTOM_DIMENSION.PT_INFORMATION,
        value: `Status:${this.gaTaggingInfo.status}, Reminder:${this.gaTaggingInfo.applicationExpiryCountdown}, ExpiryDate:${this.gaTaggingInfo.applicationExpiryDate}`,
      };
      console.log('gaTaggingConditionForAppDetail');
      dimension.push(ptInformation);
    }
    // else if (this.gaTaggingConditionForUnsuccess(currentUrl)) {
    //   console.log('gaTaggingConditionForUnsuccess');
    //   const ptInformation = {
    //     key: GA_CUSTOM_DIMENSION.PT_INFORMATION,
    //     value: `Status:${this.gaTaggingInfo.status}`,
    //   };
    //   dimension.push(ptInformation);
    // }

    if (
      currentUrl === pathUrl.loanApply.calculation ||
      currentUrl === pathUrl.promotion ||
      currentUrl === pathUrl.appInprogress
    ) {
      this.gaTagging(event);
    } else {
      this.gaTagging(event, dimension);
    }

    // ===== END GA TAGGING =====

    const previousUrl = this.previousUrl(currentUrl);
    this.prevUrl = previousUrl;
    console.log('🚀 ~ currentUrl:', currentUrl);
    console.log('🚀 ~ previousUrl:', previousUrl);
    if (!!previousUrl) {
      this.draftService.backEvtSubject.next();

      const { flow, state } = this.appState.snapshot.flow;
      if (
        this.appState.snapshot.draft &&
        this.appState.snapshot.draft.loanInfo &&
        flow !== FLOW_STATE.COMFIRM &&
        flow !== FLOW_STATE.SHELF &&
        flow !== FLOW_STATE.TOPUP_DETAIL &&
        flow !== FLOW_STATE.TOPUP_CONFIRM &&
        flow !== FLOW_STATE.CLEAR &&
        state !== FLOW_STATE.SALESHEET &&
        state !== FLOW_STATE.CALCULATE
      ) {
        this.draftService.saveDraft();
      }

      if (
        (currentUrl === pathUrl.loanApply.apply ||
          currentUrl === pathUrl.loanApply.topupDetail ||
          currentUrl === pathUrl.loanApply.calculation ||
          currentUrl === pathUrl.promotion ||
          currentUrl === pathUrl.aisKtbTermAndCon) &&
        previousUrl !== pathUrl.confirmingDetails &&
        previousUrl !== pathUrl.loanApply.calculation &&
        flow !== FLOW_STATE.RATING
      ) {
        console.log('loan apply > exitFullScreen with : ', previousUrl);
        this.jsInterfaceService.exitFullScreen(previousUrl);
      } else if (flow === FLOW_STATE.RATING) {
        console.log('exitFullScreenToPageRating');
        this.jsInterfaceService.exitFullScreenToPageRating();
      } else {
        console.log(
          'Case by pass and press back button with previousUrl? :',
          previousUrl
        );
        previousUrl
          ? this.router.navigate([previousUrl], {
              replaceUrl:
                previousUrl === pathUrl.aisConsent ||
                (currentUrl === pathUrl.aisOtp &&
                  previousUrl === pathUrl.aisKtbTermAndCon),
            })
          : this.location.back();
      }
    } else {
      console.log('go to bib -> closeWebView');
      // if (currentUrl === pathUrl.appInprogress) {
      //   return this.router.navigate([pathUrl.loanProductShelf]);
      // }
      this.jsInterfaceService.closeWebView();
    }
  }

  getHeaderClassByPage(path: string) {
    switch (path) {
      case PAGE_NAME.PRODUCT_SHELF:
        return 'header-transparent';
      case PAGE_NAME.APP_INPROGRESS:
        return 'header-color-third-white';
      case PAGE_NAME.CALCULATION:
        return 'header-color-primary-white';
      case PAGE_NAME.CONFIRM_DETAIL:
        return 'header-color-third-white';
      case PAGE_NAME.APPLY_CONFIRMATION_INFO:
        return 'header-color-third-white';
      case PAGE_NAME.APPLY_RATING:
        return 'header-color-third-white';
      default:
        return 'header-color-primary-white';
    }
  }

  close() {
    const from = this.appState.from;
    console.log('🚀 ~ HEADER > from:', from);
    if (
      from === ACCEPT_CONTRACT_FLOW ||
      from === CANCEL_FLOW.FROM_APPROVED_FLOW ||
      from === CANCEL_FLOW.FROM_UPLOAD_DOCUMENT_FLOW
    ) {
      console.log('HEADER > close > go to home');
      this.router.navigate([pathUrl.loanProductShelf]);
    } else {
      console.log('HEADER > close > dialog cancel by user');
      this.dialogService.setError('', 'cancel_by_user');
    }
  }

  gaTagging(event: EventGATagging, dimension?: FirebaseParam[]) {
    console.log('GA TAGGING');
    this.jsInterfaceService.gaTagging(event, dimension);
  }

  navigatorShare() {
    const promotion = this.appState.snapshot.promotion;
    this.jsInterfaceService.shareText(promotion.urlToShare);
  }

  ngOnChanges(): void {
    this.setProductType();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    // window.removeEventListener('popstate', this.eventNavigateBack);
    console.log('🚀 ~ ngOnDestroy ~ window.removeEventListener');
  }
}
