import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { IPendingDocuments } from '../../interfaces';
import { HttpService } from '@web-di-ld/apps/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PendingDocumentsService {
  private applications$ = new BehaviorSubject<any>({});
  selectedApplication$ = this.applications$.asObservable();

  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService
  ) {}

  async getDocList(applcationRef: string): Promise<IPendingDocuments> {
    return this.httpService
      .get<IPendingDocuments>(
        `/lfm-accept/app/v1/get-doc-list?applicationRef=${applcationRef}`
      )
      .toPromise()
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return EMPTY;
      });
  }

  async extendFollowup(applcationRef: string) {
    return this.httpService
      .post(
        `/lfm-accept/app/v1/followup-extend`,
        {
          loanAppId: applcationRef,
        },
        false
      )
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }
}
