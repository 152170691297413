import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnlineEventService {
  private onlineStatusSubject = new Subject<boolean>();

  getOnlineStatus(): Observable<boolean> {
    return this.onlineStatusSubject.asObservable();
  }

  startMonitoring(): void {
    window.addEventListener('online', () =>
      this.onlineStatusSubject.next(true)
    );
    window.addEventListener('offline', () =>
      this.onlineStatusSubject.next(false)
    );
  }
}
