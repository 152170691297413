import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'web-di-ld-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  providers: [MessageService],
})
export class ToastComponent implements OnInit {
  // Fix img path (For Success) | If You want to use another image, please implement condition for this
  imgPath = '/assets/images/ic_check-circle.png';

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {}

  showToast(type: string, title: string, description: string) {
    this.messageService.add({
      severity: type,
      summary: title,
      detail: description,
    });
  }
}
