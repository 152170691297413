import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountNumberFormatPipe } from './account-number-format.pipe';

@NgModule({
  declarations: [AccountNumberFormatPipe],
  imports: [CommonModule],
  exports: [AccountNumberFormatPipe],
})
export class AccountNumberFormatModule {}
