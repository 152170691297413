import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { IAppStatus } from '../../interfaces';
import { HttpService } from '@web-di-ld/apps/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppStatusService {
  private applications$ = new BehaviorSubject<any>({});
  selectedApplication$ = this.applications$.asObservable();

  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService
  ) {}

  async getAppStatusDetails(): Promise<IAppStatus> {
    return this.httpService
      .get<IAppStatus>(`/lfm-app-status/app/v1/app-status`)
      .toPromise()
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return EMPTY;
      });
  }

  setApplication(product: any) {
    this.applications$.next(product);
  }
}
