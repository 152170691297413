import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountNumberFormat',
})
export class AccountNumberFormatPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (value) {
      return '*** * **' + value.slice(-4, -1) + ' ' + value.slice(-1);
    }
    return '';
  }
}
