export interface IPendingDocuments {
  loanAppId: string;
  extendDay: number;
  documentType: DocumentType[];
}

export interface DocumentType {
  doc_name: string;
  doc_group: string;
  doc_desc: string;
  doc_condition: string;
}
