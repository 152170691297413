<ng-container *transloco="let t">
  <div class="error-container">
    <div class="information p-text-center">
      <img src="{{ path }}" alt="apply-reject" width="240px" />
      <h3 class="title p-mb-1 p-mt-4">
        {{ title }}
      </h3>
      <p class="p-mt-0 description" [innerHTML]="description"></p>
    </div>
  </div>

  <section
    class="section-card-button flex-fixed"
    #btnApplyFullScreenError
    refId="btnConfirmSummary"
  >
    <web-di-ld-button
      id="btnConfirmSummary"
      [label]="t('applyError.fullScreen.btnText')"
      (clickEvt)="onSubmit()"
    ></web-di-ld-button>
  </section>
</ng-container>
