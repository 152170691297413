import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { pathUrl } from '../../configs';
import {
  BE_ERROR_CODE,
  GA_CUSTOM_DIMENSION,
  GA_EVENT_ACTION,
  LOAN_PRODUCT_TYPE,
  GA_EVENT_CATEGORY,
  ERROR_TYPE,
  FE_ERROR_CODE,
  AIS_ERROR_CODE,
} from '../../core/constant/base.enum';
import { ILimitValidate } from '../../core/interfaces';
import { JsInterfaceService } from '../../core/services';
import { TranslocoService } from '@ngneat/transloco';

export interface JavascriptCallback {
  exitFullScreen(data: any): any;
}

declare const LoanWeb: JavascriptCallback;
declare const window: any;
declare const webkit: any;
@Injectable()
export class DialogErrorHandlerService
  implements ErrorHandler, HttpInterceptor {
  private _openDialog = false;
  public get openDialog() {
    return this._openDialog;
  }
  public set openDialog(value) {
    this._openDialog = value;
    if (value) {
      this.errorType = ERROR_TYPE.GENERIC_ERROR;
      this.errorCode = FE_ERROR_CODE.GENERIC;
      this.errorMessage = this.defaultErrorMessage;
      this.errorLoaded.next();
    }
  }
  dialogMessageCode = '6';
  onAcceptDialog = () => {};
  errorHttpStatusCode: number = 0;
  public displayError: boolean = false;
  public errorTitle: string = '';
  public errorCode: string = '';
  public errorMessage: string = '';
  public errorLimitValidateData: ILimitValidate = { cutOffDay: 0 };
  public errorType: ERROR_TYPE = ERROR_TYPE.GENERIC_ERROR;
  public defaultErrorMessage: string = '';

  errorLoaded = new Subject<void>();

  constructor(
    private location: Location,
    private jsInterfaceService: JsInterfaceService
  ) {}

  setError(
    title: string = '',
    errorCode: string,
    message: string = '',
    errorLimitValidateData: ILimitValidate = { cutOffDay: 0 }
  ) {
    if (this.openDialog === false) {
      // if no dialog then show fullscreen error
      this.errorTitle = title;
      this.displayError = true;
      this.errorCode = errorCode;
      this.errorMessage = message;
      this.errorLimitValidateData = errorLimitValidateData;
    }
  }

  clearError() {
    this.errorTitle = '';
    this.displayError = false;
    this.errorCode = '';
    this.errorMessage = '';
    this.errorLimitValidateData = { cutOffDay: 0 };
  }

  handleError(error: any): void {
    console.error(error);
    this.openDialog = this.displayError === false ? true : false;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.errorHttpStatusCode = error.status;
        /**
         * custom ErrorCode here!
         */
        if (error && this.checkIgnoreError(error, request)) {
          this.handleError(error);
        }

        return throwError(error);
      })
    );
  }

  checkIgnoreError(error: HttpErrorResponse, request: HttpRequest<any>) {
    if (error.error.code === BE_ERROR_CODE.NOT_CIF_ON_CBS) {
      console.log('ACHE501');
      return false;
    }

    if (
      error.url?.includes('/lfm-applications/app/v1/application/products') &&
      error.url?.includes('/draft') &&
      request.method === 'GET'
    ) {
      return false;
    }

    // 5PLUS_TL case payrollacc not found and ALL product if maintenance return 500
    if (
      error.url?.includes(`/lfm-offers/app/v1/products/`) &&
      error.url?.includes('/detail') &&
      (error.error.code === BE_ERROR_CODE.PRODUCT_EXPIRED ||
        error.error.code === BE_ERROR_CODE.APPLY_REJECT_LIMIT ||
        error.error.code === BE_ERROR_CODE.APPLY_DAILY_LIMIT ||
        error.error.code === BE_ERROR_CODE.APPLY_MONTHLY_LIMIT ||
        error.error.code === BE_ERROR_CODE.INOPROGRESS_OTHER_PRODUCT ||
        (error.url?.includes(
          `/lfm-offers/app/v1/products/${LOAN_PRODUCT_TYPE._5PLUSTL}`
        ) &&
          error.error.code === BE_ERROR_CODE.NO_PAYROLL_ACCOUNT) ||
        error.error.code ===
          BE_ERROR_CODE.TOPUP_CREDIT_LIMIT_LESS_THAN_MINIMUM ||
        error.error.code === BE_ERROR_CODE.ERROR_DIRECT_DEBIT_ACC ||
        error.error.code === BE_ERROR_CODE.ERROR_DISBURSEMENT_ACC ||
        error.error.code === BE_ERROR_CODE.ERROR_EXISTING_LOAN ||
        error.error.code === BE_ERROR_CODE.CBS_MANDATORY_FIELD_MISSING ||
        error.error.code === BE_ERROR_CODE.ACC_OPEN_IN_PERIOD ||
        error.error.code === BE_ERROR_CODE.PRODUCT_NOT_ELIGIBLE) &&
      request.method === 'GET'
    ) {
      return false;
    }

    // product closing hours
    if (
      error.url?.includes(`/lfm-offers/app/v1/products/`) &&
      error.url?.includes('/opening-hours-validation') &&
      error.error.code === BE_ERROR_CODE.PRODUCT_CLOSED &&
      request.method === 'POST'
    ) {
      return false;
    }

    // validate app inprogress
    if (
      error.url?.includes(`/lfm-offers/app/v1/products/`) &&
      error.url?.includes('/validate-app-inprogres') &&
      error.error.code === BE_ERROR_CODE.INOPROGRESS_OTHER_PRODUCT &&
      request.method === 'GET'
    ) {
      return false;
    }

    // loan app is expired
    if (
      error.url?.includes(`/lfm-applications/app/v1/application/products/`) &&
      error.url?.includes('/draft') &&
      error.status === HttpStatusCode.NotExtended &&
      request.method === 'POST'
    ) {
      return false;
    }

    // lfm-lookup/app/v1/geography/province/0/district/0/subdistrict/0
    if (
      error.url?.includes(`/province/`) &&
      error.url?.includes(`/district/`) &&
      error.url?.includes(`/subdistrict/`) &&
      error.status === HttpStatusCode.InternalServerError &&
      request.method === 'GET'
    ) {
      return false;
    }

    if (
      error.url?.includes(`/lfm-applications/app/v2/application/save`) &&
      error.status === HttpStatusCode.InternalServerError &&
      (error.error?.code === BE_ERROR_CODE.CID_NOT_APPLICABLE ||
        error.error?.code === BE_ERROR_CODE.INVALID_CBS_DATA)
    ) {
      return false;
    }

    if (
      error.url?.includes(`/lfm-applications/app/v1/customer`) &&
      (error.error?.code === BE_ERROR_CODE.CUSTOMER_MANDATORY_FIELD_MISSING ||
        error.error?.code ===
          BE_ERROR_CODE.CUSTOMER_CBS_MAILING_ADDRESS_INVALID)
    ) {
      return false;
    }

    if (
      error.url?.includes('/lfm-offers/app/v1/offers') &&
      error.error?.code === BE_ERROR_CODE.NOT_ELIGIBLE_ANY_PRODUCTS
    ) {
      return false;
    }

    if (
      error.url?.includes('/lfm-ais/app/v1/ais/check-eligible') &&
      error.status === HttpStatusCode.InternalServerError &&
      error.error?.code === AIS_ERROR_CODE.CUSTOMER_PROFILE_NOT_FOUND
    ) {
      return false;
    }

    if (
      (error.url?.includes('/lfm-ais/app/v1/ais/consent-confirm-otp') &&
        error.status === HttpStatusCode.InternalServerError &&
        error.error?.code === AIS_ERROR_CODE.INCORRECT_PWD) ||
      error.error?.code === AIS_ERROR_CODE.BLOCKED_USER_3_ATTEMPTS ||
      error.error?.code === AIS_ERROR_CODE.CONSENT_CONFIRM_OTP_EXPIRED
    ) {
      return false;
    }

    if (
      error.url?.includes('/lfm-ais/app/v1/ais/check-eligible') &&
      error.status === HttpStatusCode.InternalServerError &&
      error.error?.code === AIS_ERROR_CODE.CUSTOMER_PROFILE_NOT_FOUND
    ) {
      return false;
    }

    // if (environment.mode === 'local') {
    //   return false;
    // }

    return true;
  }

  closeDialog() {
    this.openDialog = false;
    const path = this.location.path();
    // const accessToken:string = '?accessToken='+await this.httpService.token$.toPromise();

    const strPath = path.includes('?')
      ? path.substring(0, path.lastIndexOf('?'))
      : path.substring(0, path.length);
    if (this.errorHttpStatusCode === 401) {
      this.errorHttpStatusCode = 0;
      const ua = navigator.userAgent.toLowerCase();
      const isAndroid = ua.indexOf('android') > -1;
      try {
        if (isAndroid) {
          let android = LoanWeb;
          console.log('isAndroid');
          android.exitFullScreen(null);
        } else {
          window.webkit.messageHandlers.exitFullScreen.postMessage(null);
        }
      } catch (error) {
        console.log('isWeb');
      }
    } else if (
      !strPath ||
      strPath === pathUrl.loanProductShelf ||
      strPath === pathUrl.loanApply.apply ||
      strPath === pathUrl.loanApply.topup ||
      strPath === pathUrl.loanApply.topupDetail
    ) {
      const ua = navigator.userAgent.toLowerCase();
      const isAndroid = ua.indexOf('android') > -1;
      try {
        if (isAndroid) {
          let android = LoanWeb;
          console.log('isAndroid');
          android.exitFullScreen(null);
        } else {
          window.webkit.messageHandlers.exitFullScreen.postMessage(null);
        }
      } catch (error) {
        console.log('isWeb');
      }
      // window.location.reload();
    } else if (!strPath || strPath === pathUrl.aisKtbTermAndCon) {
      // pass
    } else {
      this.location.back();
    }

    this.onAcceptDialog();
    this.onAcceptDialog = () => {};
  }

  getPageEvent(): GA_EVENT_CATEGORY | string {
    const currentUrl = this.location.path();
    switch (currentUrl) {
      case pathUrl.loanApply.apply:
        return GA_EVENT_CATEGORY.LOAN_APPLY;
      case pathUrl.loanApply.productDetail:
        return GA_EVENT_CATEGORY.PRODUCT_DETAIL;
      case pathUrl.loanApply.calculation:
        return GA_EVENT_CATEGORY.LOAN_CALCULATE;
      case pathUrl.personalInformation:
        return GA_EVENT_CATEGORY.PERSONAL_INFORMATION;
      case pathUrl.additionalInformation:
        return GA_EVENT_CATEGORY.ADDITIONAL_INFORMATION;
      case pathUrl.editInformation:
        return GA_EVENT_CATEGORY.EDIT_INFORMATION;
      case pathUrl.confirmingDetails:
        return GA_EVENT_CATEGORY.CONFIRM_DETAIL;
      case pathUrl.marketingConsent:
        return GA_EVENT_CATEGORY.MARKETING_CONSENT;
      case pathUrl.ncbConsent:
        return GA_EVENT_CATEGORY.NCB_CONSENT;
      case pathUrl.promotion:
        return GA_EVENT_CATEGORY.PROMOTION;
      case pathUrl.loanApply.topupDetail:
        return GA_EVENT_CATEGORY.TOPUP_LOAN_DETAIL;
      case pathUrl.loanApply.topupAdditional:
        return GA_EVENT_CATEGORY.TOPUP_ADDITIONAL_DETAIL;
      case pathUrl.loanApply.topupConfirm:
        return GA_EVENT_CATEGORY.TOPUP_CONFIRMATION;
      case pathUrl.editMailingAddress:
        return GA_EVENT_CATEGORY.EDIT_MAILING_ADDRESS;
      default:
        return '';
    }
  }

  gaTagging() {
    const analyticCategory = this.getPageEvent();
    if (analyticCategory) {
      this.jsInterfaceService.gaTagging(
        {
          action: GA_EVENT_ACTION.ERROR,
          category: analyticCategory,
          label: this.errorTitle
            ? `${this.errorCode || 'null'}: ${this.errorTitle}: ${
                this.errorMessage || 'null'
              }`
            : `${this.errorCode || 'null'}: ${this.errorMessage || 'null'}`,
        },
        [
          {
            key: GA_CUSTOM_DIMENSION.HTTP_STATUS_CODE,
            value: this.errorHttpStatusCode.toString() || 'null',
          },
          {
            key: GA_CUSTOM_DIMENSION.ERROR_CODE,
            value: this.errorCode || 'null',
          },
          {
            key: GA_CUSTOM_DIMENSION.IS_FIN_TXN_ERROR,
            value: 'false',
          },
          {
            key: GA_CUSTOM_DIMENSION.ERROR_TITLE,
            value: this.errorTitle || 'null',
          },
          {
            key: GA_CUSTOM_DIMENSION.ERROR_DISPLAY_TYPE,
            value: this.errorType || 'null',
          },
        ]
      );
    }
  }
}
