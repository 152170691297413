import { Injectable } from '@angular/core';
import jwt_decode, { JwtPayload } from 'jwt-decode';

interface JwtDecodeOptions extends JwtPayload {
  data: {
    cifNo: string;
    cifNoPT: string;
    leadChannel: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  constructor() {}

  decodeJWTToken(token: string): JwtDecodeOptions {
    return jwt_decode(token);
  }
}
