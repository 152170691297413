import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ERROR_TYPE } from 'apps/di-ld/src/app/core/constant/base.enum';
import { Subscription } from 'rxjs';
import { DialogErrorHandlerService } from '../../../error-handler/dialog-error-handler.service';

@Component({
  selector: 'web-di-ld-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string = '';
  @Input() buttonTitle: string = '';
  @Input() path: string = '/assets/images/API_Error.png';
  @Input() img: string = '';
  @Input() case: string | number = 1;
  @Input() moreMessage: string = '';
  @Input() moreTitle: string = '';
  @Input() maxSubmission: string = '';
  @Input() cutOffDay: number = 0;
  @Output() clickEvt = new EventEmitter();
  @Output() onBack = new EventEmitter();
  @Input() enableBackButton: boolean = false;
  width = '';

  subscription = new Subscription();

  constructor(
    public dialogService: DialogErrorHandlerService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    if (this.img == 'sadBird') {
      // Change sad bird to document reject image
      this.path = '/assets/images/illus-DGL-Reject-2.png';
      this.width = 'width: 240px !important;';
    } else if (this.img == 'sadBirdFail') {
      this.path =
        '/assets/images/foundation-illustration-contextual-vayu-application-fail.png';
      this.width = 'width: 240px !important;';
    } else if (this.img == 'reject') {
      this.path = '/assets/images/illus-DGL-Reject.png';
      this.width = 'width: 240px !important;';
    } else if (this.img == 'thankBird') {
      // Final case 5 star on accept contract
      this.path = '';
    } else if (this.img == 'callcenter') {
      this.path = '/assets/images/API_Error.png';
    } else if (this.img == 'noVerifyEmail') {
      this.path = '/assets/images/ekyc-fail-email.png';
      this.width = 'width: 240px !important;';
    } else if (this.img == 'cancel') {
      this.path = '/assets/images/app-detail-cancel-by-user.png';
      this.width = 'width: 240px !important;';
    }
  }

  ngOnChanges() {
    this.subscription.add(
      this.translocoService.selectTranslation().subscribe((translation) => {
        this.dialogService.errorType = ERROR_TYPE.FULLSCREEN;
        this.dialogService.errorTitle =
          translation['applyError.' + this.case + '.title'];
        this.dialogService.errorHttpStatusCode = 0;
        this.dialogService.errorLoaded.next();
      })
    );
  }

  emitClickEvt() {
    this.clickEvt.emit();
  }

  emitClickOnBack() {
    this.onBack.emit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  buttonText() {
    if (this.case === 'email_verify') {
      return 'common.verifyEmail';
    } else if (this.case === 'ais_x_ktb_reject_by_user') {
      return 'loanApply.applyLoan';
    } else {
      return 'common.ok';
    }
  }
}
