<div class="center">
  <img [src]="path" [style]="width" *ngIf="!isPendingAnimate" />
  <div
    *ngIf="isPendingAnimate"
    class="p-d-flex p-ai-center p-jc-center p-d-flex-col"
  >
    <ng-lottie width="241px" [options]="animateOptions"></ng-lottie>
  </div>

  <div class="title p-mb-3">
    <span [innerHTML]="title"> </span>
  </div>
  <div class="desc">
    <span class="desc-msg" [innerHTML]="description"> </span>
  </div>

  <div *ngIf="isShowLaterBtn" class="container-later">
    <ng-container *transloco="let t">
      <div class="p-grid p-grid-card">
        <div class="p-col-12">
          <div class="information">
            <p-card>
              <div class="p-d-flex" [ngClass]="isMarginTop ? '' : 'mt-8px'">
                <div>
                  <i class="pi pi-info-circle p-mr-2 i-style"></i>
                </div>
                <div class="info-message">
                  {{ waitingDesc || t('applySuccess.waitingDesc') }}
                </div>
              </div>
            </p-card>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="cancel_btn p-pt-3">
      <web-di-ld-button (clickEvt)="onLater()" [label]="btnText">
      </web-di-ld-button>
    </div>
  </div>
</div>
