import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccentureValidatorsModule } from '@web-di-ld/accenture-validators';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';

import { SwiperModule } from 'swiper/angular';

import { PinchZoomModule } from 'ngx-pinch-zoom';

import { TabViewModule } from 'primeng/tabview';

import { TranslocoRootModule } from '../../../core/transloco-root.module';
import { ImageModule } from '../../directives/image/image.module';
import { PureModule } from '../../pipe/pure/pure.module';
import { CustomThDateModule } from '../../pipe/custom-th-date/custom-th-date.module';

import { ApplyErrorComponent } from './apply-error/apply-error.component';
import { BannerComponent } from './banner/banner.component';
import { ButtonComponent } from './button/button.component';
import { CardProductShelfComponent } from './card-product-shelf/card-product-shelf.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ErrorComponent } from './error/error.component';
import { HeaderComponent } from './header/header.component';
import { ModalComponent } from './modal/modal.component';
import { PromoComponent } from './promo/promo.component';
import { VerifyComponent } from './verify/verify.component';
import { DirectiveModule } from '../../directives/directive.module';
import { StepComponent } from './step/step.component';
import { StepsModule } from 'primeng/steps';
import { DividerModule } from 'primeng/divider';
import { ReplaceNullWithTextModule } from '../../pipe/replace-null-with-text/replace-null-with-text.module';
import { StatusAcceptComponent } from './app-detail-case/status-accept/status-accept.component';
import { StatusApproveComponent } from './app-detail-case/status-approve/status-approve.component';
import { StatusPendingDocumentComponent } from './app-detail-case/status-pending-document/status-pending-document.component';
import { StatusProcessComponent } from './app-detail-case/status-process/status-process.component';
import { StatusUnsuccessComponent } from './app-detail-case/status-unsuccess/status-unsuccess.component';
import { ToastModule } from 'primeng/toast';
import { ToastComponent } from './toast/toast.component';
import { ApplyFullScreenErrorComponent } from './apply-full-screen-error/apply-full-screen-error.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { StatusConsiderComponent } from './app-detail-case/status-consider/status-consider.component';
import { AccountNumberFormatModule } from '../../pipe/account-number-format/account-number-format.module';
import { RatingComponent } from './rating/rating.component';

export function playerFactory() {
  return player;
}

const modules = [
  FormsModule,
  ReactiveFormsModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  InputNumberModule,
  SliderModule,
  SelectButtonModule,
  CheckboxModule,
  RadioButtonModule,
  PdfViewerModule,
  InputTextModule,
  DialogModule,
  TranslocoRootModule,
  CommonModule,
  AccentureValidatorsModule,
  SwiperModule,
  PinchZoomModule,
  PureModule,
  CustomThDateModule,
  AccountNumberFormatModule,
  TabViewModule,
  ImageModule,
  DirectiveModule,
  StepsModule,
  DividerModule,
  ReplaceNullWithTextModule,
  ToastModule,
];

const components = [
  ButtonComponent,
  CheckboxComponent,
  ModalComponent,
  ApplyErrorComponent,
  CardProductShelfComponent,
  HeaderComponent,
  VerifyComponent,
  ErrorComponent,
  BannerComponent,
  PromoComponent,
  StepComponent,
  StatusAcceptComponent,
  StatusApproveComponent,
  StatusPendingDocumentComponent,
  StatusProcessComponent,
  StatusUnsuccessComponent,
  ToastComponent,
  ApplyFullScreenErrorComponent,
  StatusConsiderComponent,
  RatingComponent,
];
@NgModule({
  declarations: [components],
  imports: [modules, LottieModule.forRoot({ player: playerFactory })],
  exports: [...components, ...modules],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiModule {}
