<!-- ; read: 'titleHeader' -->
<ng-container *transloco="let t">
  <div *ngIf="title" class="header card" [ngClass]="getHeaderClassByPage(path)">
    <div class="p-d-flex p-align-center p-ai-center">
      <!-- <img
        *ngIf="title != t('confirmSummary') && path != PAGE_NAME.APPLY_SUCCESS"
        src="/assets/images/back_icon1.png"
        class="arraw-back"
        (click)="navigateTo(url)"
      /> -->
      <span
        class="arraw-back"
        *ngIf="
          title != t('confirmSummary') &&
          path != PAGE_NAME.APPLY_SUCCESS &&
          path != PAGE_NAME.APPLY_RATING
        "
        (click)="navigateTo(url)"
      >
        <i class="pi pi-chevron-left"></i>
      </span>

      <div class="title" *ngIf="title">{{ title }}</div>
      <div
        *ngIf="title == t('titleHeader.confirmSummary')"
        class="close-screen"
      >
        <img src="/assets/images/ic_close.png" (click)="close()" />
      </div>
      <div
        *ngIf="share == 'true'"
        class="share-button"
        (click)="navigatorShare()"
      >
        {{ t('share') }}
      </div>
    </div>
  </div>
</ng-container>
