import { AppStateService } from 'apps/di-ld/src/app/app-state.service';
import { JwtService } from './../../../../../apps/di-ld/src/app/core/services/shared/jwt.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  take,
  tap,
  timeout,
} from 'rxjs/operators';
import { environment } from '@environments/environment';
export interface VerifyResponse {
  code: string;
  message: string;
  data: VerifyData;
}
export interface VerifyData {
  accessToken: string;
  language: string;
}

@Injectable()
export class HttpService {
  token$ = this.extractParam('authorizationCode');
  tokenApi$ = this.extractParam('tokenApi');
  productType$ = this.extractParam('productType');
  applicationRef$ = this.extractParam('applicationRef');
  aisInfo$ = this.extractParam('info');
  aisChannel$ = this.extractParam('channel');
  verifyToken: VerifyResponse | null = null;
  acceptLang = 'th-TH';
  mockMsg = '';
  environment = environment;

  get authenDglApi() {
    if (!environment) {
      return '';
    }
    return environment.dglAuthenApiBaseUrl ?? '';
  }

  get dglApiBaseUrl() {
    if (!environment) {
      return '';
    }
    return environment.dglApiBaseUrl ?? '';
  }

  get baseUrl() {
    if (!environment) {
      return '';
    }
    return environment.baseUrl ?? '';
  }

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute,
    private jwtService: JwtService,
    private appState: AppStateService
  ) {}

  get<R = any>(
    path: string,
    condition: { [params: string]: any } = {},
    authenDglApi = false
  ) {
    const payload = condition;
    const params = new HttpParams({ fromObject: payload });
    let baseUrl = authenDglApi ? this.authenDglApi : this.dglApiBaseUrl;
    if (this.environment.mode === 'local') {
      baseUrl = `${this.baseUrl}/dev`;
    }

    const httpOptions = {
      params,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache', // instruct the browser not to cache the response.
        Expires: '0', // 0 ensures the response is considered expired immediately.
      },
    };

    return (
      this.http
        // .get<R>(`${baseUrl}/dev${path}`, { params })
        .get<R>(`${baseUrl}${path}`, httpOptions)
        .pipe(
          catchError((err) => {
            console.error(err);
            return throwError(err);
          })
        )
    );
  }

  postAuthen<R = any>(
    path: string,
    body: any,
    authenDglApi: any,
    headers?: HttpHeaders
  ) {
    let options = { headers: headers } || {};
    let baseUrl = authenDglApi ? this.authenDglApi : this.dglApiBaseUrl;
    if (this.environment.mode === 'local') {
      baseUrl = `${this.baseUrl}/dev`;
    }
    return this.http.post<R>(`${baseUrl}${path}`, body, options).pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  post<R = any>(
    path: string,
    body: any,
    authenDglApi = false,
    headers?: HttpHeaders
  ) {
    let options = { headers: headers } || {};
    let baseUrl = authenDglApi ? this.authenDglApi : this.dglApiBaseUrl;
    if (this.environment.mode === 'local') {
      baseUrl = `${this.baseUrl}/dev`;
    }
    return this.http.post<R>(`${baseUrl}${path}`, body, options).pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  postFormData<R = any>(path: string, formData: FormData, authenDglApi = true) {
    const baseUrl = authenDglApi ? this.authenDglApi : this.dglApiBaseUrl;
    return this.http.post<R>(`${baseUrl}${path}`, formData).pipe(
      catchError((err) => {
        console.error(err);
        return EMPTY;
      })
    );
  }

  put<R = any>(path: string, body: any, authenDglApi = true) {
    const baseUrl = authenDglApi ? this.authenDglApi : this.dglApiBaseUrl;
    return this.http.put<R>(`${baseUrl}${path}`, body);
  }

  patch<R = any>(path: string, body: any, authenDglApi = true) {
    const baseUrl = authenDglApi ? this.authenDglApi : this.dglApiBaseUrl;
    return this.http.patch<R>(`${baseUrl}${path}`, body);
  }

  delete<R = any>(path: string, payload?: any, authenDglApi = true) {
    const baseUrl = authenDglApi ? this.authenDglApi : this.dglApiBaseUrl;
    if (!payload) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        body: payload,
      };
      return this.http.delete<R>(`${baseUrl}${path}`, options);
    } else {
      return this.http.delete<R>(`${baseUrl}${path}`);
    }
  }

  /**
   * เป็น api เส้นแรกที่เรียก เพื่อ validate-token-paotang
   * @param token token ที่ได้จาก QueryParam
   * @returns Observable จาก verify
   */
  httpVerifyToken() {
    let tokenVar;
    this.token$.subscribe((token) => {
      tokenVar = token;
    });

    let aisInfo;
    this.aisInfo$.subscribe((info) => {
      aisInfo = info;
    });
    console.log('aisInfo: ' + aisInfo);
    let aisChannel;
    this.aisChannel$.subscribe((channel) => {
      aisChannel = channel;
    });
    console.log('aisChannel: ' + aisChannel);

    return this.postAuthen<VerifyResponse>(
      `/lfm-access-channel/app/v1/validate-token-paotang`,
      {
        authorizationCode: tokenVar,
      },
      true
    ).pipe(
      tap((verify) => {
        this.acceptLang = verify.data.language || 'th-TH';
        this.verifyToken = verify;
        const accessToken = verify.data.accessToken;
        const decodeToken = this.jwtService.decodeJWTToken(accessToken);
        console.log(
          '🚀 ~ decodeToken from /validate-token-paotang:',
          decodeToken
        );
        if (decodeToken?.data) {
          const cifNo = decodeToken?.data?.cifNo;
          if (cifNo) {
            this.appState.setHasCifNo(true);
          } else {
            this.appState.setHasCifNo(false);
          }
        }
      })
      // FOR HANDLE ERROR CODE ACHE501 (NOT CIF ON CBS)
      // catchError((error) => {
      //   console.error(error);
      //   return EMPTY;
      // })
    );
  }

  tokenPaotang() {
    return this.get<VerifyResponse>(
      `/lfm-access-channel/app/v1/exchange-token-paotang`,
      {},
      true
    ).pipe(
      tap((verify) => {
        this.acceptLang = verify.data.language || 'th-TH';
        this.verifyToken = verify;
        const accessToken = verify.data.accessToken;
        const decodeToken = this.jwtService.decodeJWTToken(accessToken);
        console.log(
          '🚀 ~ decodeToken from /exchange-token-paotang:',
          decodeToken
        );
        if (decodeToken?.data) {
          const cifNo = decodeToken?.data?.cifNo;
          if (cifNo) {
            this.appState.setHasCifNo(true);
          } else {
            this.appState.setHasCifNo(false);
          }
        }
      })
    );
  }

  haveTokenApi() {
    let tokenApi = '';
    this.tokenApi$.subscribe((token) => {
      tokenApi = token;
    });
    return tokenApi != '' ? true : false;
  }

  /**
   * ดึงข้อมูลจากเส้น verify ถ้าเคยดึงแล้ว ให้ใช้ cache แทน
   * @param token token ที่ได้จาก QueryParam
   * @returns Observable จาก verify
   */
  getVerifyToken() {
    if (this.environment.mode === 'sit') {
      this.tokenApi$.subscribe((token) => {
        const data: VerifyData = {
          accessToken: token,
          language: 'th-TH',
        };
        const res: VerifyResponse = {
          code: '0',
          message: 'success',
          data: data,
        };
        this.verifyToken = res;
        this.acceptLang = 'th-TH';
      });
    }

    if (this.verifyToken) {
      return of(this.verifyToken);
    }
    return this.httpVerifyToken();
  }

  /**
   * สร้าง object เงื่อนไขใหม่ที่ value ของ properties ไม่ null
   * @param condition เงื่อนไข
   * @returns object ที่ถูก clone ที่ properties ไม่ null
   */
  purifyNotNull(condition: { [params: string]: any }) {
    const payload: { [params: string]: any } = {};
    Object.entries(condition).forEach(([key, value]) => {
      if (value !== null) {
        payload[key] = value;
      }
    });
    return payload;
  }

  /**
   * รอค่าจาก Query
   * @param param ชื่อ Parameter
   * @param time เวลา timeout ที่จะรอ QueryParameter
   * @returns
   */
  private extractParam(param: string, time = 400) {
    return this.route.queryParams.pipe(
      map((params) => params[param]),
      filter((param) => Boolean(param)),
      timeout(time),
      catchError((e) => {
        console.error(e);
        return EMPTY;
      }),
      take(1),
      shareReplay(1)
    );
  }

  public clearProductType() {
    this.productType$ = of();
  }
}
