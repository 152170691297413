import { APPLICATION_STATUS } from '../constant/base.enum';

export interface IApplications {
  applications: Applications[];
}

export interface Applications {
  applicationRef: string;
  applicationDateTime: string;
  productTypeCode: string;
  productMarketCode: string;
  productCode: string;
  productName: string;
  requestCreditLimit: number;
  interestRate: string;
  paymentTerm: number;
  paymentTermType: string;
  status: string;
  statusDesc: string;
  estimatedMonthlyInstallment: number;
  fee: string;
  status_internal: APPLICATION_STATUS;
  submitDate: string;
  statusDate: string;
  followupExpireDate?: string;
  followupExpireCountdown?: string;
  followupExpireCountdownEn?: string;
  titleTh: string;
  firstNameTh: string;
  middlenameTh: string;
  actualCreditLimit?: number;
  applicationExpiryDate?: string;
  applicationExpiryCountdown?: string;
  applicationExpiryCountdownEn?: string;
  lastNameTh: string;
  offerMaxInterestRate: number;
  maxInstallmentAmount: number;
  selectedPaymentTerm: number;
  selectedPaymentTermType: string;
  statusSelfApply: StatusSelfApply;
  email: string;
  statusRemark: string;
  productType: string;
  acceptedDate: string;
}

export interface StatusSelfApply {
  status: string;
  group: string;
  sEndFlow: boolean;
}

export interface ICheckExpired {
  message: string;
  code: string;
  data: boolean;
}

export interface ISixCheck {
  code: string;
  message: string;
  data: SIX_CHECK_TYPE;
}

export enum SIX_CHECK_TYPE {
  PENDING = 'PENDING',
  PASS = 'PASS',
  NOTPASS = 'NOTPASS',
}
