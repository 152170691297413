import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@web-di-ld/apps/http';
import { EMPTY } from 'rxjs';
import { AppStateService } from '../../../app-state.service';
import { DialogErrorHandlerService } from '../../../shared/error-handler/dialog-error-handler.service';
import { BE_ERROR_CODE } from '../../constant/base.enum';
import { CustomerDetail } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(
    private appState: AppStateService,
    private httpService: HttpService,
    private dialogService: DialogErrorHandlerService
  ) {}

  getCustomerDetail() {
    return this.httpService
      .get<CustomerDetail>(`/lfm-applications/app/v1/customer`)
      .toPromise()
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        if (
          error.error.code === BE_ERROR_CODE.CUSTOMER_MANDATORY_FIELD_MISSING
        ) {
          this.dialogService.setError(
            '',
            error.error.code,
            error.error.message
          );
        } else if (
          error.error.code ===
          BE_ERROR_CODE.CUSTOMER_CBS_MAILING_ADDRESS_INVALID
        ) {
          return error.error;
        }
        console.error(error);
        return null;
      });
  }

  public updateCustomerDetail(customerDetail: CustomerDetail) {
    this.appState.updateStateCustomerDetail(customerDetail);
  }
}
