<p-card (click)="productSelected()" class="product-card">
  <ng-template pTemplate="header">
    <div
      class="product-content"
      id="divProductShelfCard{{ productDetail.productCode }}"
    >
      <div
        class="image"
        *ngIf="productDetail?.haveTagHot || productDetail?.haveTagNew"
      >
        <img
          [alt]="
            getTextLabel(productDetail?.haveTagHot, productDetail?.haveTagNew)
          "
          [src]="
            getImageLabel(productDetail?.haveTagHot, productDetail?.haveTagNew)
          "
        />
        <label class="image-label" id="LblProductShelfTagName">{{
          getTextLabel(productDetail?.haveTagHot, productDetail?.haveTagNew)
        }}</label>
      </div>

      <img
        class="product-image"
        alt="Card"
        [src]="productDetail?.productImage || '/assets/images/banner-empty.png'"
        id="imgCard{{ productDetail.productCode }}"
      />
    </div>
  </ng-template>
  <ng-container *transloco="let t">
    <div class="content">
      <div class="title tag-line" id="divProductShelfTagLine">
        {{ productDetail?.tagLine }}
      </div>
      <div class="loan-detail p-pt-2">
        <div class="description p-d-flex p-jc-between">
          <div class="title" id="divProductShelfTitlelMaxCreditLimit">
            {{ t('loanProductShelf.productDetail.maximumLimit') }}
          </div>
          <div class="value">
            <div class="bold" id="divProductShelfMaxCreditLimit">
              {{
                productDetail?.maxCreditLimit && productDetail?.isEligible
                  ? (productDetail?.maxCreditLimit
                    | currency: ' ':'symbol':'1.0-0')
                  : '-'
              }}
              {{ t('loanApply.baht') }}
            </div>
            <!-- {{ t('loanProductShelf.productDetail.baht') }} -->
          </div>
        </div>
        <div class="description p-d-flex p-jc-between">
          <div class="title">
            {{ t('loanProductShelf.productDetail.interestRate') }}
          </div>
          <!-- NULL TYPE-->
          <div class="value" *ngIf="!productDetail?.interest?.rateType">
            <div class="bold">
              <span>-</span>
            </div>
          </div>
          <!-- FIXEDMAX TYPE -->
          <div
            class="value"
            *ngIf="
              productDetail?.interest?.rateType === LOAN_RATE_TYPE.FIXEDMAX
            "
          >
            <div class="bold">
              <span
                *ngIf="
                  productDetail?.interest?.enableSpecial &&
                  productDetail.haveCountDown &&
                  endCountDownDay > 0
                "
                class="discount"
                >{{
                  productDetail?.interest?.maxSpecialRate &&
                  productDetail?.isEligible
                    ? productDetail?.interest?.maxSpecialRate + '%'
                    : ''
                }}
              </span>
              <span
                [ngClass]="{
                  'on-discount':
                    productDetail?.interest?.enableSpecial &&
                    productDetail.haveCountDown &&
                    endCountDownDay > 0 &&
                    productDetail?.isEligible
                }"
                >{{
                  productDetail?.interest?.maxInterestRate &&
                  productDetail?.isEligible
                    ? productDetail?.interest?.maxInterestRate + '%'
                    : '-'
                }}
                {{ t('loanApply.interestType') }}</span
              >
            </div>
          </div>
          <!-- FIXEDRANGE TYPE -->
          <div
            class="value"
            *ngIf="
              productDetail?.interest?.rateType === LOAN_RATE_TYPE.FIXEDRANGE
            "
          >
            <div class="bold">
              <span
                *ngIf="
                  productDetail?.interest?.enableSpecial &&
                  productDetail.haveCountDown &&
                  endCountDownDay > 0
                "
                class="discount"
                >{{
                  productDetail?.interest?.minSpecialRate &&
                  productDetail?.isEligible
                    ? productDetail?.interest?.minSpecialRate + '%'
                    : ''
                }}
              </span>
              <span
                [ngClass]="{
                  'on-discount':
                    productDetail?.interest?.enableSpecial &&
                    productDetail.haveCountDown &&
                    endCountDownDay > 0 &&
                    productDetail?.isEligible
                }"
              >
                {{
                  productDetail?.interest?.minInterestRate &&
                  productDetail?.isEligible
                    ? productDetail?.interest?.minInterestRate + '%'
                    : '-'
                }}
              </span>
            </div>
          </div>
          <!-- INDEXMAX TYPE -->
          <div
            class="value"
            *ngIf="
              productDetail?.interest?.rateType === LOAN_RATE_TYPE.INDEXMAX
            "
          >
            <div class="bold">
              <span
                *ngIf="
                  productDetail?.interest?.enableSpecial &&
                  productDetail.haveCountDown &&
                  endCountDownDay > 0
                "
                class="discount"
              >
                {{
                  interest.indexRateType && productDetail?.isEligible
                    ? interest.indexRateType +
                      (interest.maxSpecialRate > 0 ? '+' : '-')
                    : ''
                }}{{
                  interest.maxSpecialRate && productDetail?.isEligible
                    ? getAbsolute(interest.maxSpecialRate) + '%'
                    : ''
                }}
              </span>
              <span
                [ngClass]="{
                  'on-discount':
                    productDetail?.interest?.enableSpecial &&
                    productDetail.haveCountDown &&
                    endCountDownDay > 0 &&
                    productDetail?.isEligible
                }"
              >
                {{
                  interest.indexRateType && productDetail?.isEligible
                    ? interest.indexRateType +
                      (interest.maxInterestRate > 0 ? '+' : '-')
                    : ''
                }}{{
                  interest.maxInterestRate && productDetail?.isEligible
                    ? getAbsolute(interest.maxInterestRate) + '%'
                    : '-'
                }}
              </span>
            </div>
          </div>
          <!-- INDEXRANGE TYPE -->
          <div
            class="value"
            *ngIf="
              productDetail?.interest?.rateType === LOAN_RATE_TYPE.INDEXRANGE
            "
          >
            <div class="bold">
              <span
                *ngIf="
                  productDetail?.interest?.enableSpecial &&
                  productDetail.haveCountDown &&
                  endCountDownDay > 0
                "
                class="discount"
              >
                {{
                  interest.indexRateType && productDetail?.isEligible
                    ? interest.indexRateType +
                      (interest.minSpecialRate > 0 ? '+' : '-')
                    : ''
                }}{{
                  interest.minSpecialRate && productDetail?.isEligible
                    ? getAbsolute(interest.minSpecialRate) + '%'
                    : ''
                }}
              </span>
              <span
                [ngClass]="{
                  'on-discount':
                    productDetail?.interest?.enableSpecial &&
                    productDetail.haveCountDown &&
                    endCountDownDay > 0 &&
                    productDetail?.isEligible
                }"
              >
                {{
                  interest.indexRateType && productDetail?.isEligible
                    ? interest.indexRateType +
                      (interest.minInterestRate > 0 ? '+' : '-')
                    : ''
                }}{{
                  interest.minInterestRate && productDetail?.isEligible
                    ? getAbsolute(interest.minInterestRate) + '%'
                    : '-'
                }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="countdown-label"
          id="divProductShelfCountdownDate"
          *ngIf="productDetail?.haveCountDown && endCountDownDay >= 0"
        >
          <div class="countdown-content">
            <div class="label">
              <span *ngIf="endCountDownDay > 1">
                {{
                  t('loanProductShelf.productDetail.limitTime', {
                    value: endCountDownDay
                  })
                }}</span
              >
              <span *ngIf="endCountDownDay === 1">
                {{
                  t('loanProductShelf.productDetail.limitTime', {
                    value: endCountDownDay
                  }).replace('s', '')
                }}</span
              >
              <span *ngIf="endCountDownDay === 0">
                {{ t('loanProductShelf.productDetail.lastDay') }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</p-card>
